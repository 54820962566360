


import React, { useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { API_LOGBOOK_LIST} from '../services/restService'
import { Button } from 'react-bootstrap';
import ToolTipText from './ToolTipText';


const LogbookList = ({logbookList, getLogbook, deleteLogbook, err, logbooksSize, setLogbooksSize, logbooksPage, setLogbooksPage}) => {

  
  const arraySize = useMemo(() => Math.ceil(logbookList.totalCount/logbooksSize), [logbookList, logbooksSize]);

  return (
       <div>
          <h1>Ouvrir un carnet :</h1>
          <Container style={{"overflowY":"auto", "maxHeight": "800px"}}>
            
          <Row>
                <Col> {API_LOGBOOK_LIST}</Col>
            </Row>
            <Row>
                <Col className="error"> {err}</Col>
            </Row>
             

            <Row>
              <Col>
                  <Button size="sm" variant="secondary" onClick={()=> setLogbooksPage(logbooksPage-1)} disabled={logbooksPage === 0}> {"<"} </Button>
                  {Array.from(Array(arraySize).keys()).map(key=>
                    (
                    <Button size="sm" key={key} variant={logbooksPage === key ? 'success' : "light"} onClick={()=> setLogbooksPage(key)}>{key+1}</Button>
                    ))}
                  <Button size="sm" variant="secondary" onClick={()=> setLogbooksPage(logbooksPage+1)} disabled={logbooksPage === arraySize-1}> {">"}  </Button>
                  
                    <span> Page Size :</span>
                    <input style={{"width": "40px"}} type="number" value={logbooksSize} onChange={e => setLogbooksSize(e.target.value)}/>
                </Col>
                  
            </Row>
            <Row>
              <Col xs="1"> <b>Identifiant   </b> </Col>
              <Col xs="2"> <b> Nom </b></Col>
              <Col xs="1"> <b> actions </b></Col>
              <Col xs="1"> <b> Compteurs </b></Col>
              <Col xs="3"> <b>Annuel</b> </Col>
              <Col xs="2"><b> Economies</b>  </Col>
              <Col xs="2"> <b> Ouvrir </b></Col>
            </Row>
           
            {logbookList && logbookList.data.map(lb => (

                <Row key={lb.id} style={{"marginBottom":"10px"}}>
                    <Col xs="1" style={{"fontSize": "0.6rem"}}> {lb.id}</Col>
                    <Col xs="2" > {lb.companyName}</Col>
                    <Col xs="1"> {lb.nbActions}</Col>
                    <Col xs="1"> {lb.nbMeters}</Col>
                    <Col xs="3"> 
                      <ToolTipText number={lb.totalConsumptionKWh}  digits="0" unit="kWh"/>  
                      { (lb.totalConsumptionKWhWithActions-lb.totalConsumptionKWh) !== 0 && 
                          (
                            <i style={{"color":"green", "fontSize": "0.8rem"}}> 
                              ( <ToolTipText number={ (lb.totalConsumptionKWhWithActions-lb.totalConsumptionKWh)*100/lb.totalConsumptionKWh} digits="0" unit="%"/>)
                              
                            </i>
                          )
                        }
                      <br/>
                      <ToolTipText number={lb.totalInvoice}  digits="0" unit="€"/>
                      { (lb.totalInvoiceWithActions-lb.totalInvoice) !== 0 && 
                        ( 
                          <i style={{"color":"green", "fontSize": "0.8rem"}}> 
                            ( <ToolTipText number={ (lb.totalInvoiceWithActions-lb.totalInvoice)*100/lb.totalInvoice} digits="0" unit="%"/>)
                            
                          </i>
                        )
                     }
                     <br/>
                     <ToolTipText number={lb.totalGesEmissions}  digits="0" unit="kgCO2"/>
                      { (lb.totalGesEmissionsWithActions-lb.totalGesEmissions) !== 0 && 
                        (
                          <i style={{"color":"green", "fontSize": "0.8rem"}}> 
                            ( <ToolTipText number={ (lb.totalGesEmissionsWithActions-lb.totalGesEmissions)*100/lb.totalGesEmissions} digits="0" unit="%"/>)
                            
                          </i>
                        )
                      }
                    <br/>
                    </Col>
                    <Col xs="2">
                      { lb.savedEnergyInKWh > 0 && 
                        <div>
                          <ToolTipText number={lb.savedEnergyInKWh} digits="1" unit="kWh"/><br/>
                          <ToolTipText number={lb.savedInvoice} digits="0" unit="€"/><br/>
                          <ToolTipText number={lb.totalCO2ProductionAvoided} digits="1" unit="kgCO2"/>
                        </div>
                      }
                    </Col>
                    <Col xs="2">
                      <Button size="sm" variant="primary" onClick={()=>getLogbook(lb.id)}>Ouvrir</Button>
                      <Button size="sm" variant="danger" onClick={()=>deleteLogbook(lb.id)}>Supprimer</Button>
                    </Col>
                </Row>
                
            ))}
            <Row>
              <Col>
                  <Button size="sm" variant="secondary" onClick={()=> setLogbooksPage(logbooksPage-1)} disabled={logbooksPage === 0}> {"<"} </Button>
                  {Array.from(Array(arraySize).keys()).map(key=>
                    (
                    <Button size="sm" key={key} variant={logbooksPage === key ? 'success' : "light"} onClick={()=> setLogbooksPage(key)}>{key+1}</Button>
                    ))}
                  <Button size="sm" variant="secondary" onClick={()=> setLogbooksPage(logbooksPage+1)} disabled={logbooksPage === arraySize-1}> {">"}  </Button>
                  
                    <span> Page Size :</span>
                    <input style={{"width": "40px"}} type="number" value={logbooksSize} onChange={e => setLogbooksSize(e.target.value)}/>
                </Col>
                  
            </Row>
        </Container>
      </div>
  )
}

export default LogbookList;
