import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToolTipText from './ToolTipText'
import {  getkPisAPI, API_GET_KPIS} from '../services/restService'
import { Button } from 'react-bootstrap';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';


const KPI_TEMPLATE= 
  {"savedEnergyInKWh":null,
  "averageEnergySavingsPerLogbookInKWh":null,
  "averageEarnedConsumptionPerLogbookInPercent":null,
  "totalCo2ProductionAvoided":null,
  "averageCo2ProductionAvoidedPerLogbook":null,
  "openedLogbooks":null,
  "totalInvestments":null,
  "totalActionsNumber":null,
  "averageActionsPerLogbook":null,
  "consumptionByEnergyList":[{"consumptionKWh": null, "gesEmissions": null, "invoice": null, "energy" :  {"id": null, "name": "Energie"}}],
  "consumptionByEnergyListWithActions":[{"consumptionKWh": null, "gesEmissions": null, "invoice": null, "energy" :  {"id": null, "name": "Energie"}}],
  "consumptionBySubUsageList" : [{"consumptionKWh": null, "gesEmissions": null, "invoice": null, "subUsage" :  {"id": null, "name": "Sous usage"}, "usage": {"id": null, "name": "Usage"}}],
  "consumptionBySubUsageListWithActions" : [{"consumptionKWh": null, "gesEmissions": null, "invoice": null, "subUsage" :  {"id": null, "name": "Sous usage"}, "usage": {"id": null, "name": "Usage"}}],
  "totalConsumption": null,
  "totalConsumptionWithActions": null,
  "totalInvoice" : null,
  "totalInvoiceWithActions": null,
  "totalCO2ProductionWithActions": null,
  "totalCO2Production": null,


}

ChartJS.register(ArcElement, Tooltip, Legend);

const BACKGROUNDCOLOR=[
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(25, 1, 104, 0.5)',
    'rgba(55, 54, 164, 0.5)',
    'rgba(200, 34, 20, 0.5)',
    'rgba(40, 9, 5, 0.5)',
    'rgba(30, 52, 120, 0.5)',
    'rgba(21, 255, 2, 0.5)',
    'rgba(200, 109, 54, 0.5)',
    'rgba(43, 209, 32, 0.5)',
    'rgba(12, 15, 98, 0.5)',
  ]
  
  const OPTIONS_ENERGY_PIE= {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: 'Consommation par énergie',
      },
    },
  };

const OPTIONS_SU_PIE = {...OPTIONS_ENERGY_PIE, plugins:{...OPTIONS_ENERGY_PIE.plugins, title: {display: true, text: "Consommation par Sous usage"}}}


const KPis = ({ logbookId}) => {

    
  const [show, setShow]= useState(false)
  const [kpis, setKpis]= useState({})
  const [err, setErr]=useState(null)
  const [date, setDate] = useState( (new Date()).toISOString().substring(0, 10))
  useEffect(()=>{
    getKpis()
  // eslint-disable-next-line
  }, [logbookId]);

  const handleDateChange=(ev)=> {
    if (!ev.target['validity'].valid) return;
    const dt= ev.target['value'];

    if(kpis.date !== dt )
      setErr("La date saisie ne correspond pas aux informations affichées, Cliquez sur le bouton rafraichir")
    else
      setErr(null)
      //setKpis(KPI_TEMPLATE)
    setDate(dt);
  }

  
  const handleDateBlur=(ev)=> {
    getKpis();
  }

  const getKpis = () =>{
    setKpis(KPI_TEMPLATE)
    getkPisAPI(date).then(res=>{
      if(res.message) {
        setErr(res.message)
      }
      else if(res.date === date){
        setKpis(res)
        setErr(null)
      }
    })
  }

  const getConsumptionByEnergyData= (array, key, label) => {
    if(!kpis[array]) return {}
    else
      return {
        labels: kpis[array].map(c => c?.energy?.name),
        datasets:[
          {
            label : label,
            data: kpis[array].map(c => c[key]),
            backgroundColor: BACKGROUNDCOLOR,
          }
        ]
      }
    }
    const getConsumptionBySubUsageData= (array, key, label) => {
      if(!kpis[array]) return {}

      return {
        labels: kpis[array].map(c => c?.subUsage?.name),
        datasets:[
          {
            label : label,
            data: kpis[array].map(c => c[key]),
            backgroundColor: BACKGROUNDCOLOR,
          }
        ]
      }
    }
      
  
  return (       
  <Container>
    <Row>
      <Col><h1>Kpis du Programme</h1></Col>
    </Row>
    <br/>
    <Row> <Col><i>Les gains des actions sont calculés au prorata de leur date d'application à la date saisie</i></Col></Row>
 
    <br/>
    <Row>
      <Col><span  className="error">{err} </span></Col>
    </Row>
    <Row>
      <Col>{API_GET_KPIS}</Col>
    </Row>
    <Row>
      <Col xs="6">Date de simulation (par défaut : jusqu'à maintenant) </Col>
      <Col><input type="date" value={date} onChange={handleDateChange}/>
            {err && <Button size="sm" style={{"marginLeft": "10px"}} onClick={handleDateBlur}>Rafraichir</Button>}</Col>
    </Row>
    <Row>
      <Col xs="6"> Nombre de carnets ouverts : </Col>
      <Col>{kpis.openedLogbooks}</Col>
    </Row>
    <Row>
      <Col xs="6">Nombre d'actions engagés : </Col>
      <Col>{kpis.totalActionsNumber}</Col>
    </Row>
    <Row>
      <Col xs="6">Nombre moyen d'actions engagés par carnet</Col>
      <Col>
      <ToolTipText number={kpis.averageActionsPerLogbook}  digits="2"/>
      </Col>
    </Row>

    <Row>
      <Col xs="6">Consommation totale (kWh)</Col>
      <Col>
        <ToolTipText number={kpis.totalConsumption}  digits="0" unit="kWh"/>
      </Col>
    </Row>  
    <Row>
      <Col xs="6">Consommation totale avec Actions (kWh)</Col>
      <Col>
        <ToolTipText number={kpis.totalConsumptionWithActions}  digits="0" unit="kWh"/>
      </Col>
    </Row>
    <Row>
      <Col xs="6">Emission totale annuelle (kCO2 / an)</Col>
      <Col>
        <ToolTipText number={kpis.totalCO2Production}  digits="0" unit="kWh"/>
      </Col>
    </Row>  
    <Row>
      <Col xs="6">Emission totale avec Actions (kCO2 / an)</Col>
      <Col>
        <ToolTipText number={kpis.totalCO2ProductionWithActions}  digits="0" unit="kWh"/>
      </Col>
    </Row>
    <Row>
      <Col xs="6">Facture totale annuelle (€ / an)</Col>
      <Col>
        <ToolTipText number={kpis.totalInvoice}  digits="0" unit="kWh"/>
      </Col>
    </Row>  
    <Row>
      <Col xs="6">Facture totale avec Actions (€ / an)</Col>
      <Col>
        <ToolTipText number={kpis.totalInvoiceWithActions}  digits="0" unit="kWh"/>
      </Col>
    </Row>
    
    <Row>
      <Col xs="6">kWh économisé par le programme : </Col>
      <Col>
      <ToolTipText number={kpis.savedEnergyInKWh}  digits="0" unit="kWh"/>
     </Col>
    </Row>
    <Row>
      <Col xs="6">kWh moyen économisé par carnet</Col>
      <Col>
      <ToolTipText number={kpis.averageEnergySavingsPerLogbookInKWh}  digits="0" unit="kWh"/>
      </Col>
    </Row>
    <Row>
      <Col xs="6">économie d'énergie moyenne par établissement en % :</Col>
      <Col>
      <ToolTipText number={kpis.averageEarnedConsumptionPerLogbookInPercent}  digits="0" unit="%"/>
      </Col>
    </Row>
    <Row>
      <Col xs="6"> Emissions de CO2 évité par le programme</Col>
      <Col>
      <ToolTipText number={kpis.totalCO2ProductionAvoided}  digits="0" unit="kgCO2"/>
      </Col>
    </Row>
    <Row>
      <Col xs="6"> Emissions de CO2 moyen évité par carnet </Col>
      <Col>
      <ToolTipText number={kpis.averageCo2ProductionAvoidedPerLogbook}  digits="0" unit="kgCO2"/>
      </Col>
    </Row>
    
    <Row>
      <Col xs="6">Investissement total liés aux actions (€)</Col>
      <Col>
        <ToolTipText number={kpis.totalInvestments}  digits="0" unit="€"/>
      </Col>
    </Row>
    
    <Row>
      <Col xs="3">Camemberts</Col>
      <Col xs="3"><h6>kWh</h6></Col>
      <Col xs="3"><h6>€</h6></Col>
      <Col xs="3"><h6>kgCO2</h6></Col>
    </Row>
    {show &&
     ( <Row>
        <Col xs="3">Par energie :</Col>
        <Col xs="3">{kpis.consumptionByEnergyList && kpis.consumptionByEnergyList.length>0 &&
            <Pie data={getConsumptionByEnergyData("consumptionByEnergyList", "consumptionKWh", "kWh / an")} options={OPTIONS_ENERGY_PIE} id="consumptionKWhE"  />
            }
        </Col>
        <Col xs="3">{kpis.consumptionByEnergyList && kpis.consumptionByEnergyList.length>0 &&
            <Pie data={getConsumptionByEnergyData("consumptionByEnergyList", "invoice", "€ / an")} options={OPTIONS_ENERGY_PIE} id="consumptionInvoiceE"  />
            }
        </Col>
        <Col xs="3">{kpis.consumptionByEnergyList && kpis.consumptionByEnergyList.length>0 &&
            <Pie data={getConsumptionByEnergyData("consumptionByEnergyList", "gesEmissions", "kgCO2 / an")} options={OPTIONS_ENERGY_PIE} id="consumptionInvoiceE"  />
            }
        </Col>
      </Row>)
    }
    {show &&
      <Row>
        <Col xs="3">Par energie AVEC ACTIONS :</Col>
        <Col xs="3">{kpis.consumptionByEnergyListWithActions && kpis.consumptionByEnergyListWithActions.length>0 &&
            <Pie data={getConsumptionByEnergyData("consumptionByEnergyListWithActions", "consumptionKWh", "kWh / an")} options={OPTIONS_ENERGY_PIE} id="consumptionKWhE"  />
            }
        </Col>
        <Col xs="3">{kpis.consumptionByEnergyListWithActions && kpis.consumptionByEnergyListWithActions.length>0 &&
            <Pie data={getConsumptionByEnergyData("consumptionByEnergyListWithActions", "invoice", "€ / an")} options={OPTIONS_ENERGY_PIE} id="consumptionInvoiceE"  />
            }
        </Col>
        <Col xs="3">{kpis.consumptionByEnergyListWithActions && kpis.consumptionByEnergyListWithActions.length>0 &&
            <Pie data={getConsumptionByEnergyData("consumptionByEnergyListWithActions", "gesEmissions", "kgCO2 / an")} options={OPTIONS_ENERGY_PIE} id="consumptionInvoiceE"  />
            }
        </Col>
      </Row>
    }
    {show &&
      <Row>
        <Col xs="3">Par Sous Usage :</Col>
        <Col xs="3">{kpis.consumptionBySubUsageList && kpis.consumptionBySubUsageList.length>0 &&
            <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageList", "consumptionKWh", "kWh / an")} options={OPTIONS_SU_PIE} id="consumptionKWhSU"  />
            }
        </Col>
        <Col xs="3">{kpis.consumptionBySubUsageList && kpis.consumptionBySubUsageList.length>0 &&
            <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageList", "invoice", "€ / an")} options={OPTIONS_SU_PIE} id="consumptionInvoiceSU"  />
            }
        </Col>
        <Col xs="3">{kpis.consumptionBySubUsageList && kpis.consumptionBySubUsageList.length>0 &&
            <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageList", "gesEmissions", "kgCO2 / an")} options={OPTIONS_SU_PIE} id="consumptionInvoiceSU"  />
            }
        </Col>
      </Row>
    }
    {show &&
      <Row>
        <Col xs="3">Par Sous Usage AVEC ACTIONS:</Col>
        <Col xs="3">{kpis.consumptionBySubUsageListWithActions && kpis.consumptionBySubUsageListWithActions.length>0 &&
            <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageListWithActions", "consumptionKWh", "kWh / an")} options={OPTIONS_SU_PIE} id="consumptionKWhSU"  />
            }
        </Col>
        <Col xs="3">{kpis.consumptionBySubUsageListWithActions && kpis.consumptionBySubUsageListWithActions.length>0 &&
            <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageListWithActions", "invoice", "€ / an")} options={OPTIONS_SU_PIE} id="consumptionInvoiceSU"  />
            }
        </Col>
        <Col xs="3">{kpis.consumptionBySubUsageListWithActions && kpis.consumptionBySubUsageListWithActions.length>0 &&
            <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageListWithActions", "gesEmissions", "kgCO2 / an")} options={OPTIONS_SU_PIE} id="consumptionInvoiceSU"  />
            }
        </Col>
      </Row>
    }
    <Row>
      <Col>
          <Button size="sm" variant="primary" onClick={ ()=> setShow(!show)}> {show ? "Afficher moins": "Afficher plus"}</Button>
      </Col>
    </Row>
  </Container> );
}

export default KPis;
