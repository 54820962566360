import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

import {YESNO_LIST, ENERGY_LIST, ELECTRICITYCONTRACT_LIST, GASCONTRACT_LIST, LIGHTBULBTYPE_LIST} from '../services/dataList';
import {API_DELETE_LOGBOOK, API_GET_LOGBOOK, API_UPDATE_LOGBOOK} from '../services/restService'

const MyLogbookInfo = ({ logbook, logbookPut, logbookPost, submittedSurvey, deleteLogbook, setLogBook, putLogbook, err}) => {
  
  return (       
    <Container>
            <Row><Col>{API_GET_LOGBOOK}</Col></Row>
            <Row><Col className="error">{err}</Col></Row>
            <br/>
            <Row>
              <Col> <b>Champs</b>  </Col>
              { submittedSurvey?.siret &&  <Col> <b>Envoyé au POST</b>  </Col>   }
              { logbookPost?.id && <Col> <b>Retourné par le POST</b>  </Col>}
              <Col> <b>Retourné par le GET</b> </Col>{ logbookPut?.id &&  <Col> <b>Retourné par le PUT</b>  </Col>}
              
            </Row>

            <Row>
              <Col> id :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.id} </Col>}
              { logbookPost?.id && <Col>{logbookPost.id} </Col>}
              <Col>{logbook.id} </Col>
              { logbookPut?.id && <Col>{logbookPut.id} </Col>  }
            </Row>
            <Row>
              <Col> Nom de l'établissement :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.companyName} </Col>}
              { logbookPost?.id && <Col>{logbookPost.companyName} </Col>}
              <Col><input type="text" value={logbook.companyName} onChange={e => setLogBook({...logbook, "companyName": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.companyName} </Col>}
            </Row>

            <Row>
              <Col> SIRET :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.siret} </Col>}
              { logbookPost?.id && <Col>{logbookPost.siret} </Col>}
              <Col><input type="text" value={logbook.siret} onChange={e => setLogBook({...logbook, "siret": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.siret} </Col>}
            </Row>

            <Row>
              <Col> Code NAF :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.codeNaf}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.codeNaf}   </Col>}
              <Col><input type="text" value={logbook.codeNaf} onChange={e => setLogBook({...logbook, "codeNaf": e.target.value}) } />  </Col>
              { logbookPut?.id && <Col>{logbookPut.codeNaf}   </Col>}
            </Row>
            <Row>
              <Col> Adresse :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.addressLib}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.addressLib}   </Col>}
              <Col><input type="text" value={logbook.addressLib} onChange={e => setLogBook({...logbook, "addressLib": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.addressLib}   </Col>}
            </Row>
            <Row>
              <Col> Complément d'adresse :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.additionalAddressDetails}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.additionalAddressDetails}   </Col>}
              <Col><input type="text" value={logbook.additionalAddressDetails} onChange={e => setLogBook({...logbook, "additionalAddressDetails": e.target.value}) } /> </Col>
              { logbookPut?.id && <Col>{logbookPut.additionalAddressDetails}   </Col>}
            </Row>
            <Row>
              <Col> Code postal :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.postalCode}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.postalCode}   </Col>}
              <Col><input type="text" value={logbook.postalCode} onChange={e => setLogBook({...logbook, "postalCode": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.postalCode}   </Col>}
            </Row>
            <Row>
              <Col> Nom :  </Col>
              { submittedSurvey?.name && <Col>{submittedSurvey.name}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.name}   </Col>}
              <Col><input type="text" value={logbook.name} onChange={e => setLogBook({...logbook, "name": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.name}   </Col>}
            </Row>
            <Row>
              <Col> Prénom :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.firstName}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.firstName}   </Col>}
              <Col><input type="text" value={logbook.firstName} onChange={e => setLogBook({...logbook, "firstName": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.firstName}   </Col>}
            </Row>
            <Row>
              <Col> Email :  </Col>
              { submittedSurvey?.email && <Col>{submittedSurvey.email}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.email}   </Col>}
              <Col><input type="email" value={logbook.email} onChange={e => setLogBook({...logbook, "email": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.email}   </Col>}
            </Row>

            <Row>
              <Col> Téléphone :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.phoneNumber}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.phoneNumber}   </Col>}
              <Col><input type="phone" value={logbook.phoneNumber} onChange={e => setLogBook({...logbook, "phoneNumber": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.phoneNumber}   </Col>}
            </Row>

            <Row>
              <Col> Type d'ampoule :  </Col>
              
              { submittedSurvey?.siret && <Col>{LIGHTBULBTYPE_LIST.find( el=> submittedSurvey.lightBulbTypeId === el.value)?.label}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.lightBulbType?.name}   </Col>}
              <Col><Select 
                    options={LIGHTBULBTYPE_LIST} 
                    value={LIGHTBULBTYPE_LIST.find( el=> logbook.lightBulbType?.id === el.value)} 
                    onChange={(res )=> setLogBook({...logbook, "lightBulbTypeId": res.value, "lightBulbType": {id: res.value, name: res.label}}) }
                    >
                </Select>
              </Col>
              { logbookPut?.id && <Col>{logbookPut.lightBulbType?.name}   </Col>}
            </Row>
            <Row>
              <Col> Energie de chauffage :  </Col>
              { submittedSurvey?.siret && <Col>{ENERGY_LIST.find( el=> submittedSurvey.heatingEnergyId === el.value)?.label} </Col>}
              { logbookPost?.id && <Col>{logbookPost.heatingEnergy?.name} </Col>}
              <Col><Select 
                    options={ENERGY_LIST} 
                    value={ENERGY_LIST.find( el=> logbook.heatingEnergy?.id === el.value)} 
                    onChange={(res )=> setLogBook({...logbook, "heatingEnergyId": res.value, "heatingEnergy": {id: res.value, name: res.label}}) }
                    >
                </Select>  
              </Col>
              { logbookPut?.id && <Col>{logbookPut.heatingEnergy?.name} </Col>}
            </Row>
            <Row>
              <Col> Energie d'ECS :  </Col>
              { submittedSurvey?.siret && <Col> {ENERGY_LIST.find( el=> submittedSurvey.domesticHotWaterEnergyId === el.value)?.label}</Col>}
              { logbookPost?.id && <Col> {logbookPost.domesticHotWaterEnergy?.name}</Col>}
              <Col><Select 
                    options={ENERGY_LIST} 
                    value={ENERGY_LIST.find( el=> logbook.domesticHotWaterEnergy?.id === el.value)} 
                    onChange={(res )=> setLogBook({...logbook, "domesticHotWaterEnergyId": res.value, "domesticHotWaterEnergy": {id: res.value, name: res.label}}) }
                    >
                </Select></Col>
              { logbookPut?.id && <Col> { logbookPut.domesticHotWaterEnergy?.name }</Col>}
            </Row>
            <Row>
              <Col> Surface du local :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.companyFloorSpace} </Col>}
              { logbookPost?.id && <Col>{logbookPost.companyFloorSpace} </Col>}
              <Col><input type="number" value={logbook.companyFloorSpace} onChange={e => setLogBook({...logbook, "companyFloorSpace": e.target.value}) } /> </Col>
              { logbookPut?.id && <Col>{logbookPut.companyFloorSpace} </Col>}
            
            </Row>
            <Row>
              <Col> Effectif total en ETP :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.companyPermanentStaff} </Col>}
              { logbookPost?.id && <Col>{logbookPost.companyPermanentStaff} </Col>}
              <Col><input type="number" value={logbook.companyPermanentStaff} onChange={e => setLogBook({...logbook, "companyPermanentStaff": e.target.value}) } /></Col>
              { logbookPut?.id && <Col>{logbookPut.companyPermanentStaff} </Col>}
            </Row>
            <Row>
              <Col> Présence de climatisation :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.isAirConditionerPresent ? "Oui" : "Non"} </Col>}
              { logbookPost?.id && <Col>{logbookPost.isAirConditionerPresent ? "Oui" : "Non"} </Col>}
              <Col>
              <Select 
                      options={YESNO_LIST} 
                      value={YESNO_LIST.find( el=> logbook.isAirConditionerPresent === el.value)} 
                      onChange={(res )=> setLogBook({...logbook, "isAirConditionerPresent": res.value}) }
                      >
                </Select>  
              </Col>
              { logbookPut?.id && <Col>{logbookPut.isAirConditionerPresent ? "Oui" : "Non"} </Col>}
            </Row>
            <Row>
              <Col> Chiffre d'affaire :  </Col>
              { submittedSurvey?.siret && <Col>{submittedSurvey.companyTurnover}     </Col>}
              { logbookPost?.id && <Col>{logbookPost.companyTurnover}     </Col>}
              <Col><input type="number" value={logbook.companyTurnover} onChange={e => setLogBook({...logbook, "companyTurnover": e.target.value}) } />   </Col>
              { logbookPut?.id && <Col>{logbookPut.companyTurnover}     </Col>}
            </Row>
            <Row>
              <Col> Contrat Electricité :  </Col>
              { submittedSurvey?.siret && <Col>{ELECTRICITYCONTRACT_LIST.find( el=> submittedSurvey.electricityContractId === el.value)?.label}   </Col>}
              { logbookPost?.id && <Col>{logbookPost.electricityContract?.name}   </Col>}
              <Col><Select 
                    options={ELECTRICITYCONTRACT_LIST} 
                    value={ELECTRICITYCONTRACT_LIST.find( el=> logbook.electricityContract?.id === el.value)} 
                    onChange={(res )=> setLogBook({...logbook, "electricityContractId": res.value, "electricityContract": {id: res.value, name: res.label}}) }
                    >
                </Select>  
              </Col>
              { logbookPut?.id && <Col>{logbookPut.electricityContract?.name}   </Col>}
            </Row>
            <Row>
              <Col> Puissance souscrite en kVA :  </Col>
              { submittedSurvey?.siret && <Col> {submittedSurvey.electricityContractedPower}   </Col>}
              { logbookPost?.id && <Col> {logbookPost.electricityContractedPower}   </Col>}
              <Col> <input type="number" value={logbook.electricityContractedPower} onChange={e => setLogBook({...logbook, "electricityContractedPower": e.target.value}) } />  </Col>
              { logbookPut?.id && <Col> {logbookPut.electricityContractedPower}   </Col>}
            </Row>
            <Row>
              <Col> Contrat Gaz :  </Col>
              { submittedSurvey?.siret &&  <Col>{GASCONTRACT_LIST.find( el=> submittedSurvey.gasContractId === el.value)?.label}  </Col>}
              { logbookPost?.id &&  <Col>{logbookPost.gasContract?.name }  </Col>}
              <Col><Select
                    options={GASCONTRACT_LIST} 
                    value={GASCONTRACT_LIST.find( el=> logbook.gasContract?.id === el.value)} 
                    onChange={(res )=> setLogBook({...logbook, "gasContractId": res.value, "gasContract": {id: res.value, name: res.label}}) }
                    >
                </Select>  
              </Col>
              { logbookPut?.id &&  <Col>{logbookPut.gasContract?.name }  </Col>}
            </Row>
            <br/>
            <Row>
              <Col> </Col>
              { submittedSurvey?.siret && <Col> </Col>}
              { logbookPost?.id && <Col> </Col>}
              <Col><Button size="sm" variant="secondary" onClick={putLogbook}> Enregistrer ({API_UPDATE_LOGBOOK})</Button> </Col>
              { logbookPut?.id && <Col></Col>}
            </Row>
            <br/>
            <br/>
            <Row>
              <Col></Col>
              <Col><Button size="sm" variant="danger" onClick={deleteLogbook}>Supprimer mon carnet ( {API_DELETE_LOGBOOK} )</Button></Col>
              { <Col></Col>}
            </Row>
            </Container>
  
  
    );
}

export default MyLogbookInfo;
