
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import EquipmentList from './components/EquipmentList'
import ActionList from './components/ActionList'
import Survey from './components/Survey';
import LogbookList from './components/LogbookList';
import ConsumptionList from './components/ConsumptionList'
import KPis from './components/KPis';
import MyLogbookInfo from './components/MyLogbookInfo';

import { URL_API_DEFAULT, API_KEY_DEFAULT, postSurveyAPI, getLogbookAPI, getEquipmentsAPI, deleteLogbookAPI, getLogbookListAPI, putLogbookAPI, getDashboardAPI, API_GET_DASHBOARD, getLogbookMeterListAPI} from './services/restService'
import { Dashboard } from './components/Dashboard';
import { Button } from 'react-bootstrap';
import { LogbookMeterList } from './components/LogbookMeterList';
import { LogbookChart } from './components/LogbookChart';


const DEFAULT_PAGE=0;
const DEFAULT_SIZE=10;

function App() {

  const [apiUrl, setApiUrl]= useState(URL_API_DEFAULT);
  const [apiKey, setApiKey]= useState(API_KEY_DEFAULT);

  const [logbook, setLogBookGet]=useState(null)
  const [logbookPost, setLogBookPost]=useState(null)
  const [logbookPut, setLogBookPut]=useState(null)
  const [errLogbook, setErrLogbook]=useState(null)

  const [dashboard, setDashboard]=useState(null)
  const [errDashboard, setErrDashboard]=useState(null)


  const [logbookList, setLogbookList]=useState([])
  const [errLogbookList, setErrLogbookList]=useState(null)

  const [equipmentList, setEquipmentList] = useState([])
  const [errEquipment, setErrEquipment]=useState(null)

  const [submittedSurvey, setSubmittedSurvey]=useState({})
  const [errSurvey, setErrSurvey]=useState(null)

  
  const [logbookMeterList, setLogbookMeterList]=useState(null);
  const [errLogbookMeterList, setErrorLogbookMeterList]=useState(null);

  const [logbooksPage, setLogbooksPage] = useState(DEFAULT_PAGE);
  const [logbooksSize, setLogbooksSize] = useState(DEFAULT_SIZE);  
  useEffect(()=>{
    if(apiUrl){
      localStorage.setItem("apiUrl", apiUrl)
    } 
    
    if(apiKey){
      localStorage.setItem("apiKey", apiKey)
    }

  // eslint-disable-next-line
  }, [apiKey, apiUrl])

  useEffect(()=>{
    const searchParams = new URLSearchParams(window.location.search);
    if(searchParams.has('id')){
      getLogbook(searchParams.get('id'))
    }
    else{
      reset();
    }
     // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    

    let myLogbooksPage= logbooksPage;
    if(logbookList.totalCount)  {
      let MAX_LOGBOOK_PAGE = Math.ceil(logbookList.totalCount/Math.min(logbooksSize,logbookList.totalCount))-1;
      myLogbooksPage = Math.min(logbooksPage, MAX_LOGBOOK_PAGE)
    }

    if(logbooksPage !== myLogbooksPage) setLogbooksPage(myLogbooksPage)
    
    getLogbookList(myLogbooksPage, logbooksSize)
     // eslint-disable-next-line
  }, [logbooksPage, logbooksSize])

  const reset=()=>{
    setLogBookGet(null)
    setLogBookPut(null)
    setLogBookPost(null)
    setEquipmentList([])
    setDashboard(null)
    setErrDashboard(null)
    setErrLogbook(null)
    setErrLogbookList(null)
    setErrEquipment(null)
    setErrSurvey(null)
    setLogbookMeterList(null);
    setLogbooksPage(DEFAULT_PAGE)
    setLogbooksSize(DEFAULT_SIZE)
    getLogbookList(DEFAULT_PAGE, DEFAULT_SIZE)
    const searchParams = new URLSearchParams(window.location.search);
    if( searchParams.has("id") ){
        searchParams.delete("id");  
        window.location.search = searchParams.toString();
    }
  }


  const getLogbookList = (page, size)=>{
    setLogbookList({totalCount: logbookList?.totalCount ||1, data:[]})
    getLogbookListAPI(page, size).then( res=>{ 
      if(!res.message){
          setLogbookList(res)
      }
      else{
          setErrLogbookList(res.message)
      }
  
    });
  }

  const submitSurvey=(survey)=>{

    postSurveyAPI(survey).then(res=>{
      if(!res.message){
        console.log(res) 
        res["electricityContractId"]=res?.electricityContract?.id;
        res["gasContractId"]=res?.gasContract?.id;
        res["domesticHotWaterEnergyId"]=res?.domesticHotWaterEnergy?.id;
        res["heatingEnergyId"]=res?.heatingEnergy?.id;
        res["lightBulbTypeId"]=res?.lightBulbType?.id;
        setLogBookPost(res)
        setSubmittedSurvey(survey)
        getLogbook(res.id)
        console.log("Logbook Created !")
      }
      else{
        console.log(res.message)
        setErrSurvey(res.message)
      }
    })
  }

  
  const getDashboard = (logbookId)=>{
    getDashboardAPI(logbookId).then(res =>{
      if(!res.message){
        console.log(res)
        setDashboard(res)  
        console.log("get Dashboard !")
      }
      else{
        console.log(res.message)
        setErrDashboard(res.message)
      }
    })
  }


  const getLogbook=(logbookId)=>{
      getLogbookAPI(logbookId).then(res =>{
        if(!res.message){
          console.log(res)
          res["electricityContractId"]=res?.electricityContract?.id;
          res["gasContractId"]=res?.gasContract?.id;
          res["domesticHotWaterEnergyId"]=res?.domesticHotWaterEnergy?.id;
          res["heatingEnergyId"]=res?.heatingEnergy?.id;
          res["lightBulbTypeId"]=res?.lightBulbType?.id;  
          setLogBookGet(res)  
          console.log("get Logbook !")
          getEquipments(res.id);
          getDashboard(res.id);
          getLogbookMeterList(res.id);
          
          const searchParams = new URLSearchParams(window.location.search);
          if(! searchParams.has("id") ){
            searchParams.set("id", res.id);    
            window.location.search = searchParams.toString();
          }
          
          

        }
        else{
          console.log(res.message)
          setErrLogbook(res.message)
        }

      })
  }

  const putLogbook=() =>{
    putLogbookAPI(logbook).then(rs=>{
      if(rs.message){
        setErrLogbook(rs.message)
      }
      else{
        rs["electricityContractId"]=rs?.electricityContract?.id;
        rs["gasContractId"]=rs?.gasContract?.id;
        rs["domesticHotWaterEnergyId"]=rs?.domesticHotWaterEnergy?.id;
        rs["heatingEnergyId"]=rs?.heatingEnergy?.id;
        rs["lightBulbTypeId"]=rs?.lightBulbType?.id;
        setErrLogbook(null)
        setLogBookPut(rs);
      }
      getLogbook(logbook.id);
    });
  }
  const deleteLogbook=(id)=>{
    if(!logbook?.id && !id) return

    const idToDelete =  logbook?.id ? logbook?.id  : id;
    deleteLogbookAPI(idToDelete).then(res=>{
      if(res.message){
        console.log(res.message)
        setErrLogbookList(res.message)
      }
      else
        console.log("Logbook Successfuly deleted")
      reset();
    })
  }


  const getLogbookMeterList=(logbookId) =>{
    getLogbookMeterListAPI(logbookId).then(res =>{
      if(!res.message){
        console.log(res)
        setLogbookMeterList(res)  
        setErrorLogbookMeterList(null)
        console.log("get LogbookMeterList !")
      }
      else{
        console.log(res.message)
        setLogbookMeterList(null)  
        setErrorLogbookMeterList(res.message)
      }
    })
    
  }

  const getEquipments=(logbookId)=>{
      getEquipmentsAPI(logbookId).then(res=>{
      if(!res.message){
        console.log(res)
        setEquipmentList(res)  
        console.log("Get Equipments success !")
      }
      else{
        console.log(res.message)
        setErrEquipment(res.message)
      }
    })
  }
  
  return (
    <div className="App">
      <br/>
      <br/>
      <br/>

       <Container>
        
      <br/>
        <Row>
          <Col xs="2">URL API : </Col>
          <Col xs="5"><input style={{"width":"900px"}} value={apiUrl} onChange={e => setApiUrl(e.target.value)}/></Col>
          <Col xs="5"></Col>
        </Row>
      <br/>
        <Row>
          <Col xs="2">KEY API : </Col>
          <Col xs="5"><input style={{"width":"900px"}} value={apiKey} onChange={e => setApiKey(e.target.value)}/></Col>
        </Row>
      <br/>
        <Row>
          <Col><Button variant="warning"  size="sm"onClick={()=> reset()}> réinitialiser la page </Button></Col>
        </Row>
        
      <br/>
      </Container>


      <br/>
      <br/>
      <br/>
      <Container>
        <Row>
          <Col xs="2"></Col>
          <Col xs="8">
            <KPis logbookId={logbook?.id}></KPis>
          </Col>
          <Col xs="2"></Col>
        </Row>
      </Container>
      <br/>
      <br/>

      { !logbook &&
      <Container>
        <Row>
          <Col xs="4">
              <Survey  submitSurvey={submitSurvey}  err={errSurvey}></Survey>
          </Col>
          <Col xs="8">
            {logbookList.data &&
              (<LogbookList  
                    logbookList={logbookList} 
                    deleteLogbook={deleteLogbook} 
                    getLogbook={getLogbook} 
                    err={errLogbookList}
                    logbooksSize={logbooksSize}
                    setLogbooksSize={setLogbooksSize}
                    logbooksPage={logbooksPage}
                    setLogbooksPage={setLogbooksPage}
                ></LogbookList>)
            }
          </Col>
        </Row>
      </Container>

      }



      { logbook && logbook.id &&
      <div>
          <h1>Mon Carnet:</h1>


          { logbook && logbook.id && 
          <MyLogbookInfo logbook={logbook} submittedSurvey={submittedSurvey} deleteLogbook={deleteLogbook} setLogBook={setLogBookGet} putLogbook={putLogbook} logbookPut={logbookPut} logbookPost={logbookPost} err={errLogbook}></MyLogbookInfo>
          
          }
          
          <br/>
          <br/>
          {
            dashboard && dashboard.totalConsumptionKWh && 
            <Dashboard dashboard={dashboard} err={errDashboard}></Dashboard>
          }
          { !dashboard &&
            <Container>
              <Row><Col><h1>Dashboard</h1></Col></Row>
              <Row><Col >{API_GET_DASHBOARD}</Col></Row>
              <Row><Col className="error">{errDashboard}</Col></Row>
            </Container>
          }
          <br/>
          <br/>
          {logbook && 
          <LogbookMeterList logbookId={logbook.id} refreshLogbook={() => getLogbook(logbook.id)} err={errLogbookMeterList} logbookMeterList={logbookMeterList}></LogbookMeterList>
          }
          
          <br/>
          <br/>

          {logbook && 
          <LogbookChart logbookId={logbook.id} ></LogbookChart>} 
          <br/>
          <br/>

          { logbook && logbook.id && 
            <ConsumptionList logbookId={logbook.id} refreshLogbook={() => getLogbook(logbook.id)}></ConsumptionList>
        }
          <br/>
          <br/>
          <br/>
          { logbook && logbook.id && 
            <EquipmentList equipmentList={equipmentList} setEquipmentList={setEquipmentList} logbookId={logbook.id} refreshLogbook={() => getLogbook(logbook.id)} err={errEquipment}></EquipmentList>
      }

          { logbook && logbook.id && 
            <ActionList  logbookId={logbook.id} refreshLogbook={() => getLogbook(logbook.id)} equipmentList={equipmentList}></ActionList>
           }
      </div>
      }


      <br/>
      <br/>
      <br/>


     
    </div>
  );
}

export default App;
