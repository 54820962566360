import React, { useState } from 'react';
import Select from 'react-select'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {YESNO_LIST, SURVEY_TEMPLATE, ENERGY_LIST, ELECTRICITYCONTRACT_LIST, GASCONTRACT_LIST, LIGHTBULBTYPE_LIST} from '../services/dataList';

import { API_SURVEY } from '../services/restService';
import { Button } from 'react-bootstrap';



const Survey = ({submitSurvey, err}) => {

  const [survey, setSurvey] = useState(SURVEY_TEMPLATE);
  
  return (
       <div >
          <h1>Questionnaire :</h1>
          <Container>
          <Row>
            <Col>{API_SURVEY}</Col>
          </Row>
          <Row>
              <Col> SIRET :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.siret} onChange={(e) => setSurvey({...survey, siret: e.target.value})}/>             
              </Col>
            </Row>
            <Row>
              <Col> Nom de l'établissement :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.companyName} onChange={(e) => setSurvey({...survey, companyName: e.target.value})}/>
              </Col>
            </Row>

            <Row>
              <Col> Code NAF :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.codeNaf} onChange={(e) => setSurvey({...survey, codeNaf: e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col> Adresse :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.addressLib} onChange={(e) => setSurvey({...survey, addressLib: e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col> Complément d'adresse :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.additionalAddressDetails} onChange={(e) => setSurvey({...survey, additionalAddressDetails: e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col> Code postal :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.postalCode} onChange={(e) => setSurvey({...survey, postalCode: e.target.value})}/>
              </Col>
            </Row>

            <Row>
              <Col> Nom :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.name} onChange={(e) => setSurvey({...survey, name: e.target.value})}/>
              </Col>
            </Row>

            
            <Row>
              <Col> Prénom :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.firstName} onChange={(e) => setSurvey({...survey, firstName: e.target.value})}/>
              </Col>
            </Row>

            <Row>
              <Col> Email :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="email" value={survey.email} onChange={(e) => setSurvey({...survey, email: e.target.value})}/>
              </Col>
            </Row>

            <Row>
              <Col> Téléphone :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="tel" value={survey.phoneNumber} onChange={(e) => setSurvey({...survey, phoneNumber: e.target.value})}/>
              </Col>
            </Row>

            

            <Row>
              <Col> Type d'ampoule :  </Col>
              <Col>
                <Select 
                    options={LIGHTBULBTYPE_LIST} 
                    value={LIGHTBULBTYPE_LIST.find( el=> survey.lightBulbTypeId === el.value)} 
                    onChange={(res )=> setSurvey({...survey, "lightBulbTypeId": res.value}) }
                    >
                </Select>
              </Col>
            </Row>
            <Row>
              <Col> Energies des équipements :  </Col>
              <Col>
                <Select 
                    options={ENERGY_LIST.filter(e => e.value <= 4)} 
                    value={ENERGY_LIST.filter(el => survey.equipmentEnergyList.find(e => e === el.value))}
                    onChange={(res )=> setSurvey({...survey, equipmentEnergyList: res.map(r => r.value)})}
                    isMulti
                    >
                </Select>
              </Col>
            </Row>
            <Row>
              <Col> Energie de chauffage :  </Col>
              <Col>
                <Select 
                    options={ENERGY_LIST} 
                    value={ENERGY_LIST.find( el=> survey.heatingEnergyId === el.value)} 
                    onChange={(res )=> setSurvey({...survey, "heatingEnergyId": res.value}) }
                    >
                </Select>
              </Col>
            </Row>
            <Row>
              <Col> Energie d'ECS :  </Col>
              <Col>
              <Select 
                    options={ENERGY_LIST} 
                    value={ENERGY_LIST.find( el=> survey.domesticHotWaterEnergyId === el.value)} 
                    onChange={(res )=> setSurvey({...survey, "domesticHotWaterEnergyId": res.value})} 
                    >
                </Select>
              </Col>
            </Row>
            <Row>
              <Col> Surface du local :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.companyFloorSpace} onChange={(e) => setSurvey({...survey, companyFloorSpace: e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col> Effectif total en ETP :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.companyPermanentStaff} onChange={(e) => setSurvey({...survey, companyPermanentStaff: e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col> Chiffre d'affaire :  </Col>
              <Col>
                <input style={{"width": "220px"}} type="text" value={survey.companyTurnover} onChange={(e) => setSurvey({...survey, companyTurnover: e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col> Présence de climatisation :  </Col>
              <Col>
                <Select 
                      options={YESNO_LIST} 
                      value={YESNO_LIST.find( el=> survey.isAirConditionerPresent === el.value)} 
                      onChange={(res )=> setSurvey({...survey, "isAirConditionerPresent": res.value}) }
                      >
                </Select>  
              </Col>
            </Row>
            <Row>
              <Col> Contrat Electricité :  </Col>
              <Col>
              <Select 
                    options={ELECTRICITYCONTRACT_LIST} 
                    value={ELECTRICITYCONTRACT_LIST.find( el=> survey.electricityContractId === el.value)} 
                    onChange={(res )=> setSurvey({...survey, "electricityContractId": res.value}) }
                    >
                </Select>
              </Col>
            </Row>
            <Row>
              <Col> Puissance souscrite en kVA :  </Col>
              <Col>
                 <input style={{"width": "220px"}} type="number" value={survey.electricityContractedPower} onChange={(e) => setSurvey({...survey, electricityContractedPower: e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col> Contrat Gaz :  </Col>
              <Col>
                <Select 
                    options={GASCONTRACT_LIST} 
                    value={GASCONTRACT_LIST.find( el=> survey.gasContractId === el.value)} 
                    onChange={(res )=> setSurvey({...survey, "gasContractId": res.value}) }
                    >
                </Select>    
              </Col>
            </Row>
            <Row>
              <Col>
                <Button  size="sm" variant="primary" onClick={()=> submitSurvey(survey)}> Créer mon carnet </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                  {err && <span className="error">{err}</span>}
              </Col>
            </Row>
        </Container>
      </div>
  )
}

export default Survey;
