import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { getActionsAPI, API_ACTION_LIST, putActionAPI, API_UPDATE_ACTION, deleteActionAPI, API_DELETE_ACTION, postNewActionAPI, API_POST_ACTION, simulatActionAPI, API_SIMULATE_ACTION} from '../services/restService'

import {USAGE_LIST} from '../services/dataList'
import Select from 'react-select'
import ToolTipText from './ToolTipText';
import { Button } from 'react-bootstrap';

const ACTION_TEMPLATE={
    "id": null,
    "actionId": 'c787caf2-51bb-4c1c-b6da-5ecf60a64e38',
    "investment": 1000,
    "energySavedEquipmentPercent": 21,
    "applicationDate": new Date().toISOString(),
    "recurrence": 60,
    "isAlreadyActive": false,
    "isEffective": false,
    "logbook": {},
    "usageId": 2,
    "logbookEquipmentIdList": []
}
const USAGE_EQUIPEMENTS=7

const ActionList = ({logbookId, refreshLogbook, equipmentList}) => {

  const [actionList, setActionList] = useState([])
  const [err, setErr] = useState(null);
  const [newAction, setNewAction]= useState(ACTION_TEMPLATE)
  
  useEffect(()=>{
    if(logbookId)
      getActions();
  // eslint-disable-next-line
  }, [logbookId])

  const simulatAction = ()=>{
    const action={...newAction, logbookId: logbookId};
    
    simulatActionAPI(action).then(res=>{
      if(res.message){
        setErr(res.message)
      }
      else{
        const result ={
          energySavedPercent: res[0].energySavedPercent, 
          energySavedEuros: res[0].energySavedEuros, 
          energySavedKWh: res[0].energySavedKWh, 
          greenHouseGasesAvoided: res[0].greenHouseGasesAvoided
        }
        setNewAction({...newAction, ...result})
      }
    })
  }
  const postNewAction = ()=>{

    const action={...newAction, logbookId: logbookId};
    console.log(action)
    postNewActionAPI(action).then(res=>{
      if(res.message){
        setErr(res.message)
      }
      else{
        refreshLogbook();
        getActions();
        setNewAction({...ACTION_TEMPLATE,    "applicationDate": new Date().toISOString()})
      }
    })
  }
  

  const updateActions = ()=>{
    // eslint-disable-next-line
    actionList.filter(e => e.edited).map( (action, cb) => {
      putActionAPI(action).then(res=>{
        if(res.message)
          setErr(res.message)
        cb();
      })
    })
    setTimeout(()=> {
        refreshLogbook(); 
        getActions()
    }, 100)

  }

  const deleteAction = (id)=>{
    deleteActionAPI(id).then(res => {
      if(res.message){
        setErr(res.message)
      }
      getActions();
    }
    );
  }
  const getActions=()=>{
    getActionsAPI(logbookId).then(res=>{
      if(!res.message){
        console.log(res)
        setActionList(res.map(a => {
          return  { ...a, 
              "logbookEquipmentIdList": a.logbookEquipments?.map(e=>e.id),
              "usageId" : a.usage?.id,
            }
          }));  
        console.log("Get Actions success !")
      }
      else{
        console.log(res.message)
        setErr(res.message)
      }
    })
  }
  
  return (
          <Container>
            <Row>
              <Col><h2>Actions :</h2>
              </Col>
            </Row>
            <br/>
            <Row> <Col><i>Les gains des actions sont calculés sur 1 année complète</i></Col></Row>
            <br/>
            <Row>
                <Col><span>{API_ACTION_LIST} </span>
                </Col>
              </Row><Row>
                <Col><span className="error">{err} </span>
                </Col>
              </Row>
              <Row style={{"fontSize":"0.8em", "textAlign":"center"}}>
                <Col  xs="1"><b>id</b></Col>
                <Col  xs="1"><b>actionId</b></Col>
                <Col  xs="1"><b>investment</b></Col>
                <Col  xs="1" ><b>energySaved EquipmentPercent</b></Col>
                <Col  xs="1"><b>applicationDate</b></Col>
                <Col  xs="1"><b>recurrence</b></Col>
                <Col  xs="1"><b>isAlreadyActive | isEffective</b></Col>
                <Col  xs="1"><b>usage</b></Col>
                <Col  xs="2"><b>Equipement(s)</b></Col>
                <Col  xs="1"><b>Résultats</b></Col>
                <Col  xs="1"><b>{API_DELETE_ACTION}</b></Col>
              </Row>
             
                { actionList && actionList.length >0 &&
                (actionList.sort((a,b)=> new Date(a.createdAt) - new Date(b.createdAt)).map(action =>
                    ( <Row key={action.id} style={{"backgroundColor": action.edited ? 'green': ''}}>
                        <Col  xs="1">{action.id}</Col>
                        <Col  xs="1"><input value={action.actionId} onChange={r => setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, actionId: r.target.value, edited:true}} else return a}))} style={{"width": "130px"}} type="text"/></Col>
                        <Col  xs="1"><input value={action.investment} onChange={r => setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, investment: r.target.value, edited:true}} else return a}))} style={{"width": "50px"}} type="number"/></Col>
                        <Col  xs="1"><input value={action.energySavedEquipmentPercent} onChange={r => setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, energySavedEquipmentPercent: r.target.value, edited:true}} else return a}))}  style={{"width": "40px"}} type="number"/></Col>
                        <Col  xs="1"><input value={action.applicationDate.substring(0,16)} onChange={r => setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, applicationDate: r.target.value+':00', edited:true}} else return a}))}  style={{"width": "130px"}}   type="datetime-local"/></Col>
                        <Col  xs="1"><input value={action.recurrence} onChange={r => setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, recurrence: r.target.value, edited:true}} else return a}))}  style={{"width": "40px"}} type="number"/></Col>
                        <Col  xs="1">{action.isAlreadyActive}
                              <input checked={action.isAlreadyActive} onChange={r => setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, isAlreadyActive: !a.isAlreadyActive, edited:true}} else return a}))}  type="checkbox"/>
                              <input checked={action.isEffective} onChange={r => setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, isEffective: !a.isEffective, edited:true}} else return a}))}  type="checkbox"/>
                        </Col>
                        <Col  xs="1">
                            <Select
                                options={USAGE_LIST}
                                value={USAGE_LIST.find(u => action.usageId === u.value)}
                                onChange={r => setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, usageId: r.value, edited:true}} else return a}))} 
                            >
                            </Select>
                        </Col>
                        <Col  xs="2">
                        { action.usageId === USAGE_EQUIPEMENTS &&
                            <Select
                                options={equipmentList.map(e => ({value: e.id, label: e.equipment.label}))}
                                value={equipmentList.filter(el => action.logbookEquipmentIdList?.find(e => e === el.id)).map(e => ({value: e.id, label: e.equipment.label}))}
                                onChange={(res )=> setActionList(actionList.map(a => { if(a.id===action.id){ return {...a, "logbookEquipmentIdList": res.map(r => r.value), edited:true}} else return a}))} 
                                isMulti
                            >
                            </Select>
                            }
                            
                        </Col>
                        
                        <Col  xs="1"> 
                          <ToolTipText number={action.energySavedPercent}  digits="2" unit="%"/>
                          <br/>
                          <ToolTipText number={action.energySavedKWh}  digits="2" unit="kWh"/>
                          <br/>
                          <ToolTipText number={action.greenHouseGasesAvoided}  digits="2" unit="kgCO2"/>
                          <br/>
                          <ToolTipText number={action.energySavedEuros}  digits="2" unit="€"/>
                        </Col>
                        <Col  xs="1">
                          <Button  size="sm" variant="danger" onClick={()=> deleteAction(action.id)}>Supprimer</Button>
                        </Col>
                  
                      </Row>
                    )
                  )
                )
                }
                 <Row >
                        <Col xs="1"></Col>
                        <Col  xs="1"><input value={newAction.actionId} onChange={r => setNewAction({...newAction, actionId: r.target.value})} style={{"width": "130px"}} type="text"/></Col>
                        <Col  xs="1"><input value={newAction.investment} onChange={r => setNewAction({...newAction, investment: r.target.value})} style={{"width": "50px"}} type="number"/></Col>
                        <Col  xs="1"><input value={newAction.energySavedEquipmentPercent} onChange={r => setNewAction({...newAction, energySavedEquipmentPercent: r.target.value})} style={{"width": "40px"}} type="number"/></Col>
                        <Col  xs="1"><input value={newAction.applicationDate.substring(0,16)} onChange={r => setNewAction({...newAction, applicationDate: r.target.value+':00'})} style={{"width": "130px"}} type="datetime-local"/></Col>
                        <Col  xs="1"><input value={newAction.recurrence} onChange={r => setNewAction({...newAction, recurrence: r.target.value})} style={{"width": "40px"}} type="number"/></Col>
                        <Col  xs="1">
                          <input checked={newAction.isAlreadyActive} onChange={r => setNewAction({...newAction, isAlreadyActive: !newAction.isAlreadyActive})} type="checkbox"/>
                          <input checked={newAction.isEffective} onChange={r => setNewAction({...newAction, isEffective: !newAction.isEffective})} type="checkbox"/>
                        </Col>
                        <Col  xs="1">
                            <Select
                                options={USAGE_LIST}
                                value={USAGE_LIST.find(u => newAction.usageId === u.value)}
                                onChange={r => setNewAction({...newAction, usageId: r.value})}
                            >
                            </Select>
                        </Col>
                        <Col  xs="2">
                            { newAction.usageId === USAGE_EQUIPEMENTS &&
                            <Select
                                options={equipmentList.map(e => ({value: e.id, label: e.equipment.label}))}
                                value={equipmentList.filter(el => newAction.logbookEquipmentIdList?.find(e => e === el.id)).map(e => ({value: e.id, label: e.equipment.label}))}
                                onChange={(res )=> setNewAction({...newAction, "logbookEquipmentIdList": res.map(r => r.value)})}
                                isMulti
                            >
                            </Select>
                            }
                        </Col> 
                        <Col  xs="1"> 
                        <ToolTipText number={newAction.energySavedPercent}  digits="2" unit="%"/>
                          <br/>
                          <ToolTipText number={newAction.energySavedKWh}  digits="2" unit="kWh"/>
                          <br/>
                          <ToolTipText number={newAction.greenHouseGasesAvoided}  digits="2" unit="kgCO2"/>
                          <br/>
                          <ToolTipText number={newAction.energySavedEuros}  digits="2" unit="€"/>
                        </Col>
                        <Col  xs="1">
                          <Button size="sm" variant="primary" onClick={()=> postNewAction()} disabled={ ! (newAction.usageId !== USAGE_EQUIPEMENTS || newAction.logbookEquipmentIdList.length > 0 )}>Ajouter <br/>({API_POST_ACTION} )</Button>
                          <Button size="sm" variant="info" onClick={()=> simulatAction()} disabled={ ! (newAction.usageId !== USAGE_EQUIPEMENTS || newAction.logbookEquipmentIdList.length > 0 )}>Simuler <br/>({API_SIMULATE_ACTION} )</Button>
                        </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                  <Col >
                    <Button size="sm" variant="primary" onClick={updateActions}> Mettre à jour les actions ({API_UPDATE_ACTION})</Button>
                  </Col>
                  <Col >
                    <Button size="sm" variant="warning" onClick={getActions}> Réinitialiser les actions</Button>
                  </Col>
                </Row>
                
          <br/>
          <br/>
          <br/>
          <br/>
          </Container>
          
  );
}

export default ActionList;
