

export const API_KEY_DEFAULT = localStorage.getItem("apiKey");

export const URL_API_DEFAULT= localStorage.getItem("apiUrl") ||  'https://blw-dev.energiesdemain.org/api';



const httpQuery=(url, method, body)=>{

  const apiUrl= localStorage.getItem("apiUrl")
  const apiKey= localStorage.getItem("apiKey")
  return fetch(apiUrl+url, {
    method: method,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      "Authorization": "Bearer "+ apiKey
    },
  }).then(res=>{
    if(res.message){
      return {message: res.message}
    }
    return res.json()
  })
  .then(res=>{
    if(res.message){
      return {message: res.message}
    }
    else{
      return res
    }
  })
  .catch(err=>{
      if(err.message){
        return {message: err.message} 
      }

      return {message: "HTTP ERROR : "+ err.status}
      
});

}

/* KPI */

export const  API_GET_KPIS = "GET /api/kpis";
export const getkPisAPI= (date)=>{
  return httpQuery('/kpis?date='+date.substring(0,10), 'GET')
  .then(res=>{
    return res.ok ? res.json() : res
  }).then(res=>{
    return {...res, date}
  })
}


/* LOGBOOK */

export const API_SURVEY = 'POST /api/logbook/'
export const postSurveyAPI=( survey)=>{
  return httpQuery('/logbook', 'POST', survey)   
}

export const API_GET_LOGBOOK = "GET /api/logbook/{id}"
export const getLogbookAPI = ( id)=>{
  return httpQuery('/logbook/'+id, 'GET')  
 
}

export const API_UPDATE_LOGBOOK = "PUT /api/logbook/";
export const putLogbookAPI= (logbook)=>{
  return httpQuery('/logbook/'+logbook.id, 'PUT', logbook);
}
export const API_DELETE_LOGBOOK = "DELETE /api/logbook/{id}"
export const deleteLogbookAPI = (id)=>{
  return httpQuery('/logbook/'+id, 'DELETE')
}

export const API_GET_DASHBOARD = " GET /api/logbook/{id}/dashboard"
export const getDashboardAPI = ( id)=>{
  return httpQuery('/logbook/'+id+'/dashboard', 'GET')  
 
}
export const checkProofsSendEmailAPI = () =>{
  const apiUrl= localStorage.getItem("apiUrl")
  const apiKey= localStorage.getItem("apiKey")
  return fetch(apiUrl.substring(0, apiUrl.length-4)+'/internal/technical/send-email', {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      "Authorization": "Bearer "+ apiKey
    },
  }).then(res=>{
    if(res.message){
      return {message: res.message}
    }
    return res.json()
  })
  .then(res=>{
    if(res.message){
      return {message: res.message}
    }
    else{
      return res
    }
  })
  .catch(err=>{
      if(err.message){
        return {message: err.message} 
      }

      return {message: "HTTP ERROR : "+ err.status}
      
  });
}

//export const API_FORCE_REFRESH_METER = "GET /internal/technical/cron-consumption"
export const forceRefreshMetersAPI = ( )=>{
  
  const apiUrl= localStorage.getItem("apiUrl")
  const apiKey= localStorage.getItem("apiKey")
  return fetch(apiUrl.substring(0, apiUrl.length-4)+'/internal/technical/cron-consumption', {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      "Authorization": "Bearer "+ apiKey
    },
  }).then(res=>{
    if(res.message){
      return {message: res.message}
    }
    return res.json()
  })
  .then(res=>{
    if(res.message){
      return {message: res.message}
    }
    else{
      return res
    }
  })
  .catch(err=>{
      if(err.message){
        return {message: err.message} 
      }

      return {message: "HTTP ERROR : "+ err.status}
      
});
}

export const API_GET_LOGBOOK_METER = "GET /api/logbook/{id}/meters"
export const getLogbookMeterListAPI = ( id)=>{
  return httpQuery('/logbook/'+id+'/meters', 'GET')  
}

export const API_DELETE_METER= "DELETE /api/logbook/{id}/meter/{type}/{meterId}";
export const deleteMeterAPI=(logbookId, type,meterId)=>{
  return httpQuery(`/logbook/${logbookId}/meter/${type.toLowerCase()}/${meterId}`, 'DELETE')  
}


export const API_POST_METER= "POST /api/logbook/{id}/meter/{type}";
export const postMeterAPI= (logbookId, meter)=>{
  const meterToSend={...meter, logbookId}
  return httpQuery(`/logbook/${logbookId}/meter/${meter.type.toLowerCase()}`, 'POST', meterToSend)  
}

export const API_LOGBOOK_LIST = 'GET /api/logbooks/'
export const getLogbookListAPI=(page, size)=>{
  return httpQuery('/logbooks?page='+page+'&size='+size, 'GET')  
}

/* CONSUMPTION */
export const API_GET_CONSUMPTIONS = "GET /logbook/{id}/consumptions" 
export const getConsumptionListAPI = ( logbookId)=>{
  return httpQuery('/logbook/'+logbookId+"/consumptions", 'GET')  
}


export const API_UPDATE_CONSUMPTION= "PUT /api/logbook-consumption/{id}"
export const putConsumptionAPI = (  consumption)=>{
  return httpQuery('/logbook-consumption/'+consumption.id, 'PUT', consumption)  
}


/* EQUIPMENT */

export const API_EQUIPMENT_ALL_LIST = 'GET /api/equipments/'
export const getAllEquipmentsAPI=()=>{
  return httpQuery('/equipments/', 'GET')  
}


export const API_EQUIPMENT_LIST = 'GET /api/logbook/{id}/equipments/'
export const getEquipmentsAPI=( logbookId)=>{
  return httpQuery('/logbook/'+logbookId+'/equipments/', 'GET')  
}


export const API_UPDATE_EQUIPMENT = "PUT /api/logbook-equipment/{id}"
export const putEquipmentAPI= ( equipment)=>{
  return httpQuery('/logbook-equipment/'+equipment.id, 'PUT', equipment)  
}


export const API_POST_EQUIPMENT= "POST /api/logbook-equipment"
export const postNewEquipmentAPI= ( equipment) => {
  return httpQuery('/logbook-equipment/', 'POST', equipment)  
}


export const API_DELETE_EQUIPMENT = "DELETE /api/logbook-equipment/{id}"
export const deleteEquipmentAPI= ( id)=>{
  return httpQuery('/logbook-equipment/'+id, 'DELETE')
}





/* Actions */


export const API_ACTION_LIST = 'GET /api/logbook/{id}/actions/'
export const getActionsAPI=( logbookId)=>{
  return httpQuery('/logbook/'+logbookId+'/actions/', 'GET')  
}


export const API_UPDATE_ACTION = "PUT /api/logbook-action/{id}"
export const putActionAPI= ( action)=>{
  return httpQuery('/logbook-action/'+action.id, 'PUT', action)  
}


export const API_POST_ACTION= "POST /api/logbook-action"
export const postNewActionAPI= ( action) => {
  return httpQuery('/logbook-action/', 'POST', action)  
}
export const API_SIMULATE_ACTION = "POST /api/logbook-action/simulate";
export const simulatActionAPI= ( action) => {
  return httpQuery('/logbook-action/simulate', 'POST', [action]) 
}

export const API_DELETE_ACTION = "DELETE /api/logbook-action/{id}"
export const deleteActionAPI= ( id)=>{
  return httpQuery('/logbook-action/'+id, 'DELETE')
}

export const API_GET_LOGBOOK_CHART = "GET /api/logbook-chart/{logbookId}/{energyId}/{step}/{startDate}/{endDate}";
export const  getChartDataAPI=(logbookId, energyId, step, startDate, endDate)=>{
  startDate= startDate.toISOString().replaceAll('/', '%2F');
  endDate= endDate.toISOString().replaceAll('/', '%2F');
  return httpQuery(`/logbook-chart/${logbookId}/${energyId}/${step}/${startDate}/${endDate}`, 'GET');
}
