import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {API_EQUIPMENT_LIST, putEquipmentAPI, API_UPDATE_EQUIPMENT, deleteEquipmentAPI, API_DELETE_EQUIPMENT, getAllEquipmentsAPI, postNewEquipmentAPI, API_POST_EQUIPMENT} from '../services/restService'

import Select from 'react-select'
import { Button } from 'react-bootstrap';


const EquipmentList = ({ logbookId, refreshLogbook, equipmentList, setEquipmentList, err}) => {
  const [allEquipmentList, setAllEquipmentList] = useState([])
  const [myErr, setErr] = useState(err);
  const[ newEquipmentId, setNewEquipmentId]= useState(0)
  const[ newEquipmenQDeterminant, setNewEquipmentQdeterminant]= useState(null)
    useEffect(()=>{
      setErr(err)
    }, [err])

    useEffect(()=>{
      if(logbookId){
        getAllEquipmentsAPI().then(res =>
          setAllEquipmentList(res.sort((a,b)=> Date.parse(a.createdAt) - Date.parse(b.createdAt)))
          )
        }
    // eslint-disable-next-line
    }, [logbookId])

  const postNewEquipment = ()=>{
    const myEquipment={ 
        logbookId: logbookId,
        equipmentId: newEquipmentId,
        quantityDeterminant: newEquipmenQDeterminant,
        equipment: {}
    }

    postNewEquipmentAPI(myEquipment).then(res=>{
      if(res.message){
        setErr(res.message)
      }
      else{
        refreshLogbook();
        setNewEquipmentId(null);
        setNewEquipmentQdeterminant(0);
      }
    })
  }
  const updateEquipments = ()=>{
    // eslint-disable-next-line
    equipmentList.filter(e => e.edited).map( (e, cb) => {
      putEquipmentAPI(e).then(res=>{
        if(res.message)
          setErr(res.message)
        cb();
      })
    })
    setTimeout(()=> {
        refreshLogbook(); 
    }, 100)

  }

  const deleteEquipment = (id)=>{
    deleteEquipmentAPI(id).then(res => {
      console.log(res)
      if(res?.message){
        setErr(res.message)
      }
      refreshLogbook();
    }
    );
  }
 
  
  return (
          <Container>
            <Row>
              <Col><h2>Equipements:</h2>
              </Col>
            </Row>
            <Row>
                <Col><span>{API_EQUIPMENT_LIST} </span>
                </Col>
              </Row>
            <Row>
                <Col>
                  <span  className="error">{myErr} </span>
                </Col>
            </Row>
            <Row>
                <Col xs={2}><b>id logbook/équipement</b></Col>
                <Col xs={3}><b>Label (identifiant de l'équipement)</b></Col>
                <Col xs={1}><b>Energie principale (id) / secondaire (id) </b></Col>
                <Col xs={2}><b>famille (id)</b></Col>
                <Col xs={1}><b>Quantité du determinant</b></Col>
                <Col xs={2}><b>Determinant (id)</b></Col>
                <Col xs={1}><b>{API_DELETE_EQUIPMENT}</b></Col>
              </Row>
                { equipmentList && equipmentList.length >0 &&
                (equipmentList.map(equ =>
                    ( <Row key={equ.id} style={{"backgroundColor": equ.edited ? 'green': ''}}>
                        <Col  xs={2} style={{"fontSize" : "0.9em"}}>
                          {equ?.id ||"??"}
                        </Col>
                        <Col xs={3}>
                          {equ?.equipment?.label ||"??"}  ( {equ?.equipment?.id ||"??"})
                        </Col>
                        <Col  xs={1}>
                          { equ?.equipment?.energy?.name ||"??" } ({ equ?.equipment?.energy?.id ||"??" })
                          <br />
                          { equ?.equipment?.secondaryEnergy?.id ? " +" +equ?.equipment?.secondaryEnergy?.name + "("+ equ?.equipment?.secondaryEnergy?.id +")" : ""}
                        </Col>
                        <Col  xs={2}>
                        {equ?.equipment?.family?.name ||"??"} ({equ?.equipment?.family?.id ||"??"})
                        </Col>
                        <Col xs={1}>
                        <input style={{"width": "90px"}} type="number" value={equ?.quantityDeterminant} onChange={v=> {
                            setEquipmentList(equipmentList.map(e =>{ 
                              if(e.id === equ.id){ return {...e, quantityDeterminant : v.target.value, edited: true}} 
                              else {return e}
                              }
                              ))
                            }}/>
                        </Col>
                        <Col xs={2}>
                        {equ?.equipment?.consumptionDeterminant?.name ||"??"} ({equ?.equipment?.consumptionDeterminant?.id ||"??"})
                        </Col>
                        <Col  xs={1}>
                          <Button size="sm" variant="danger"onClick={()=> deleteEquipment(equ.id)}>Supprimer</Button>
                        </Col>
                  
                      </Row>
                    )
                  )
                )
                }
                <Row>
                  <Col xs={2}></Col>
                  <Col xs={3}>
                    <Select
                          options={allEquipmentList }
                          value={ allEquipmentList.find(e => e.id === newEquipmentId)}
                          onChange={(res )=>  setNewEquipmentId(res.id) }
                          >
                    </Select>
                  </Col>
                  <Col xs={1}>{allEquipmentList.find(e => e.id === newEquipmentId)?.energy?.name ||'??'} / {allEquipmentList.find(e => e.id === newEquipmentId)?.secondaryEnergy?.name }</Col>
                  <Col xs={2}> {allEquipmentList.find(e => e.id === newEquipmentId)?.family?.name}</Col>
                  <Col xs={1}> <input style={{"width": "90px"}} type="number" value={newEquipmenQDeterminant} onChange={v=> {setNewEquipmentQdeterminant(v.target.value)}}/></Col>
                  <Col xs={2}>{allEquipmentList.find(e => e.id === newEquipmentId)?.consumptionDeterminant?.name || '??'}</Col>
                  <Col xs={1}>
                      <Button size="sm" variant="primary"
                        onClick={postNewEquipment}
                        disabled={!newEquipmentId || !(newEquipmenQDeterminant > 0)} 
                        >Ajouter ( { API_POST_EQUIPMENT})
                      </Button>
                  </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                  <Col >
                    <Button size="sm" variant="primary" onClick={updateEquipments}> Mettre à jour les équipements ({API_UPDATE_EQUIPMENT})</Button>
                  </Col>
                  <Col >
                    <Button size="sm" variant="warning" onClick={refreshLogbook}> Réinitialiser les équipements</Button>
                  </Col>
                </Row>
                
          <br/>
          <br/>
          <br/>
          <br/>
          </Container>
          
  );
}

export default EquipmentList;
