import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ToolTipText from './ToolTipText';

import { API_GET_DASHBOARD} from '../services/restService'
import { Button } from 'react-bootstrap';

ChartJS.register(ArcElement, Tooltip, Legend);

const BACKGROUNDCOLOR=[
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(25, 1, 104, 0.5)',
    'rgba(55, 54, 164, 0.5)',
    'rgba(200, 34, 20, 0.5)',
    'rgba(40, 9, 5, 0.5)',
    'rgba(30, 52, 120, 0.5)',
    'rgba(21, 255, 2, 0.5)',
    'rgba(200, 109, 54, 0.5)',
    'rgba(43, 209, 32, 0.5)',
    'rgba(12, 15, 98, 0.5)',
  ]
  
  const OPTIONS_ENERGY_PIE= {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: 'Consommation par énergie',
      },
    },
  };

const OPTIONS_SU_PIE = {...OPTIONS_ENERGY_PIE, plugins:{...OPTIONS_ENERGY_PIE.plugins, title: {display: true, text: "Consommation par Sous usage"}}}
export const Dashboard= ({dashboard, err})=>{

    const [show, setShow]=useState(false);

    const getConsumptionByEnergyData= (array, key, label) => {
      if(!dashboard[array]) return {}
        
        return {
          labels: dashboard[array].map(c => c?.energy?.name),
          datasets:[
            {
              label : label,
              data: dashboard[array].map(c => c[key]),
              backgroundColor: BACKGROUNDCOLOR,
            }
          ]
        }
      }
      const getConsumptionBySubUsageData= (array, key, label) => {
        if(!dashboard[array]) return {}

        return {
          labels: dashboard[array].map(c => c?.subUsage?.name),
          datasets:[
            {
              label : label,
              data: dashboard[array].map(c => c[key]),
              backgroundColor: BACKGROUNDCOLOR,
            }
          ]
        }
      }
        

    return (

        <Container>
            <Row><Col><h1>Dashboard</h1></Col></Row>
            <Row><Col >{API_GET_DASHBOARD}</Col></Row>
            <Row><Col className="error">{err}</Col></Row>
            <Row>
                <Col>
                <Container>
                        <Row>
                            <Col> <h5>Economies totales réalisées </h5></Col>
                        </Row>
                        <Row> <Col>  </Col></Row>
                        <Row>
                        <Col><h2><ToolTipText number={ dashboard.savedEnergyInKWh}  digits="2" /></h2> </Col>
                        <Col><h2><ToolTipText number={ dashboard.totalCO2ProductionAvoided}  digits="2"/></h2> </Col>
                        <Col><h2><ToolTipText number={ dashboard.savedInvoice}  digits="2" /></h2> </Col>
                        </Row>
                        
                        <Row>
                        <Col><h6>kWh/an</h6> </Col>
                        <Col><h6>kgCO2/an</h6> </Col>
                        <Col><h6>euros HT/an</h6> </Col>
                        </Row>
                          <Row>
                            <Col> <h5>Economies annuelles </h5></Col>
                        </Row>
                        <Row> <Col>  </Col></Row>
                        <Row>
                        <Col><h2><ToolTipText number={ dashboard.annualSavedEnergyInKWh}  digits="2" /></h2> </Col>
                        <Col><h2><ToolTipText number={ dashboard.annualTotalCO2ProductionAvoided}  digits="2"/></h2> </Col>
                        <Col><h2><ToolTipText number={ dashboard.annualSavedInvoice}  digits="2" /></h2> </Col>
                        </Row>
                        
                        <Row>
                        <Col><h6>kWh/an</h6> </Col>
                        <Col><h6>kgCO2/an</h6> </Col>
                        <Col><h6>euros HT/an</h6> </Col>
                        </Row>
                        <Row>
                            <Col> <h5>Facture annuelle (sans actions )</h5></Col>
                        </Row>
                        <Row> <Col>  </Col></Row>
                        <Row>
                        <Col><h2><ToolTipText number={ dashboard.totalConsumptionKWh}  digits="2" /></h2> </Col>
                        <Col><h2><ToolTipText number={ dashboard.totalGesEmissions}  digits="2"/></h2> </Col>
                        <Col><h2><ToolTipText number={ dashboard.totalInvoice}  digits="2" /></h2> </Col>
                        </Row>
                        
                        <Row>
                        <Col><h6>kWh/an</h6> </Col>
                        <Col><h6>kgCO2/an</h6> </Col>
                        <Col><h6>euros HT/an</h6> </Col>
                        </Row>
                    </Container>
                    {dashboard.totalConsumptionKWhWithActions> 0 && (
                    <Container>
                        <Row>
                            <Col> <h5>Facture annuelle (Avec actions )</h5></Col>
                        </Row>
                        <Row> <Col> </Col></Row>
                        <Row>
                        <Col><h5><ToolTipText number={dashboard.totalConsumptionKWhWithActions}  digits="2" /> </h5> </Col>
                        <Col><h5><ToolTipText number={dashboard.totalGesEmissionsWithActions}  digits="2"/></h5> </Col>
                        <Col><h5><ToolTipText number={dashboard.totalInvoiceWithActions}  digits="2" /></h5> </Col>
                        </Row>
                        
                        <Row>
                        <Col><h6>kWh/an </h6> </Col>
                        <Col><h6>kgCO2/an</h6> </Col>
                        <Col><h6>euros HT/an</h6> </Col>
                        </Row>

                        <Row>
                        <Col><h6>(<ToolTipText number={100*(dashboard.totalConsumptionKWhWithActions - dashboard.totalConsumptionKWh ) / dashboard.totalConsumptionKWh }  digits="0" unit="%"/> )</h6></Col>
                        <Col><h6>(<ToolTipText number={100*(dashboard.totalGesEmissionsWithActions - dashboard.totalGesEmissions ) / dashboard.totalGesEmissions }  digits="0" unit="%"/> )</h6></Col>
                        <Col><h6>(<ToolTipText number={100*(dashboard.totalInvoiceWithActions - dashboard.totalInvoice ) / dashboard.totalInvoice }  digits="0" unit="%"/> )</h6></Col>
                        </Row>
                    </Container>
                    )}

                    <br/>
                    <br/>
                  { show &&
                    <Container>
                        <Row><Col><h5>Camemberts de conso/emission/facture par energie :</h5></Col></Row>
                        <Row>   
                          <Col  xs="2"></Col>
                            <Col xs="3">
                            {dashboard.consumptionByEnergyList && dashboard.consumptionByEnergyList.length>0 &&
                                <Pie data={getConsumptionByEnergyData("consumptionByEnergyList", "consumptionKWh", "kWh / an")} options={OPTIONS_ENERGY_PIE} id="consumptionE"  />
                                }
                            </Col>
                            <Col xs="3">
                                {dashboard.consumptionByEnergyList && dashboard.consumptionByEnergyList.length>0 &&
                                <Pie data={getConsumptionByEnergyData("consumptionByEnergyList", "invoice", "Euros HT/an")} options={OPTIONS_ENERGY_PIE} id="invoiceE"  />
                                }
                            </Col>
                            <Col xs="3">
                            {dashboard.consumptionByEnergyList && dashboard.consumptionByEnergyList.length>0 &&
                                <Pie data={getConsumptionByEnergyData("consumptionByEnergyList", "gesEmissions", "kgCO2 emis / an")} options={OPTIONS_ENERGY_PIE} id="gesE"  />
                                }
                            </Col>
                            
                          <Col xs="1" > </Col>
                        </Row>
                        <br/>
                        <Row><Col><h5>Camemberts de conso/emission/facture par energie avec actions engagées / terminées : </h5></Col></Row>
                        <Row>   
                          <Col xs="2"></Col>
                            <Col  xs="3">
                            {dashboard.consumptionByEnergyListWithActions && dashboard.consumptionByEnergyListWithActions.length>0 &&
                                <Pie data={getConsumptionByEnergyData("consumptionByEnergyListWithActions", "consumptionKWh", "kWh / an")} options={OPTIONS_ENERGY_PIE} id="consumptionEFA"  />
                                }
                            </Col>
                            <Col xs="3">
                                {dashboard.consumptionByEnergyListWithActions && dashboard.consumptionByEnergyListWithActions.length>0 &&
                                <Pie data={getConsumptionByEnergyData("consumptionByEnergyListWithActions", "invoice", "Euros HT/an")} options={OPTIONS_ENERGY_PIE} id="invoiceEFA"  />
                                }
                            </Col>
                            <Col xs="3">
                            {dashboard.consumptionByEnergyListWithActions && dashboard.consumptionByEnergyListWithActions.length>0 &&
                                <Pie data={getConsumptionByEnergyData("consumptionByEnergyListWithActions", "gesEmissions", "kgCO2 emis / an")} options={OPTIONS_ENERGY_PIE} id="gesEFA"  />
                                }
                            </Col>
                          <Col xs="1"></Col>
                        </Row>
                              

                    </Container>
                  }
                  
                  { show &&
                    <Container>
                        <Row><Col><h5>Camemberts de conso/emission/facture par Sous usages :</h5></Col></Row>
                        <Row>   
                            <Col  xs="2"></Col>
                            <Col  xs="3">
                            {dashboard.consumptionBySubUsageList && dashboard.consumptionBySubUsageList.length>0 &&
                                <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageList", "consumptionKWh", "kWh / an")} options={OPTIONS_SU_PIE} id="consumptionKWhSU"  />
                                }
                            </Col>
                            <Col  xs="3">
                                {dashboard.consumptionBySubUsageList && dashboard.consumptionBySubUsageList.length>0 &&
                                <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageList", "invoice", "Euros HT/an")} options={OPTIONS_SU_PIE} id="invoiceSU"  />
                                }
                            </Col>
                            <Col xs="3">
                            {dashboard.consumptionBySubUsageList && dashboard.consumptionBySubUsageList.length>0 &&
                                <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageList", "gesEmissions", "kgCO2 emis / an")} options={OPTIONS_SU_PIE} id="gesSU"  />
                                }
                            </Col>
                            <Col xs="1"></Col>
                        </Row>
                        <br/>
                        <Row><Col><h5>Camemberts de conso/emission/facture par Sous usages avec actions engagées / terminées : </h5></Col></Row>
                        <Row>   
                          
                        <Col xs="2"></Col>
                            <Col xs="3">
                            {dashboard.consumptionBySubUsageListWithActions && dashboard.consumptionBySubUsageListWithActions.length>0 &&
                                <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageListWithActions", "consumptionKWh", "kWh / an")} options={OPTIONS_ENERGY_PIE} id="consumptionKWhSU"  />
                                }
                            </Col>
                            <Col  xs="3">
                                {dashboard.consumptionBySubUsageListWithActions && dashboard.consumptionBySubUsageListWithActions.length>0 &&
                                <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageListWithActions", "invoice", "Euros HT/an")} options={OPTIONS_ENERGY_PIE} id="invoiceSU"  />
                                }
                            </Col>
                            <Col xs="3">
                            {dashboard.consumptionBySubUsageListWithActions && dashboard.consumptionBySubUsageListWithActions.length>0 &&
                                <Pie data={getConsumptionBySubUsageData("consumptionBySubUsageListWithActions", "gesEmissions", "kgCO2 emis / an")} options={OPTIONS_ENERGY_PIE} id="gesSU"  />
                                }
                            </Col>
                            <Col xs="1"></Col>
                            
                            
                        </Row>
                    

                    </Container>

                  }
                  <Button size="sm" variant="primary" onClick={ ()=> setShow(!show)}> {show ? "Afficher moins": "Afficher plus"}</Button>
                </Col>
            </Row>
        </Container>

    )
}
