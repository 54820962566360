import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {API_GET_CONSUMPTIONS, getConsumptionListAPI, API_UPDATE_CONSUMPTION, putConsumptionAPI} from '../services/restService'

import ToolTipText from './ToolTipText';
import { Button } from 'react-bootstrap';


const ConsumptionList = ({logbookId, refreshLogbook}) => {

  const [consumptionByEnergyList, setConsumptionByEnergyList] = useState([])
  const [err, setErr] = useState(null)

  useEffect(()=>{
     getConsumptionList()
     // eslint-disable-next-line
  }, [logbookId, refreshLogbook])


  const getConsumptionList=()=>{
    getConsumptionListAPI(logbookId)
    .then(res=>{
      if(res.message){
        setErr(res.message)
      }
      else
        setConsumptionByEnergyList(res)
    })
  }

  const updateConsumptionByEnergyList =() => {
    
     // eslint-disable-next-line
    consumptionByEnergyList.filter(c=> c.edited).map((c, cb)=>{
      putConsumptionAPI(c)
        .then(res =>{
          if(res.message)
            setErr(res.message)
          cb()
        })

        getConsumptionList()
        refreshLogbook()
    })
    
  }


  return (
        
    <div>

      <br/>

      <Container>
        <Row><Col><h1> Mes consommations : </h1></Col></Row>

        <Row><Col><span  className="error">{err}  </span></Col></Row>
        <Row><Col>({API_GET_CONSUMPTIONS} ) </Col></Row>
        
        <br/>
        <Row>
                  <Col xs="2"><b>Id</b></Col>
                  <Col xs="2"><b>Energie (id)</b></Col>
                  <Col xs="3"><b>Contrat (id)</b></Col>
                  <Col xs="2"><b>kWh</b></Col>
                  <Col xs="1"><b>kgCO2</b></Col>
                  <Col xs="1"><b>Euros</b></Col>
                  <Col xs="1"><b>Réel</b></Col>
                </Row>
                
                {consumptionByEnergyList && consumptionByEnergyList.length>0 && consumptionByEnergyList.map(conso => (
                    <Row  style={{"backgroundColor": conso.edited ? 'green': ''}} key={conso.id}>
                      <Col xs="2">{conso.id || "??"}</Col>
                      <Col xs="2">{conso?.energyCost?.contractType?.energy?.name || "??"} ({conso?.energyCost?.contractType?.energy?.id || "??"})</Col>
                      <Col xs="3">{conso?.energyCost?.contractType?.name || ""} ({conso?.energyCost?.contractType?.id || ""}){conso?.energyCost?.contractedPower ? " ("+conso?.energyCost?.contractedPower+" kVA)" : "" }</Col>
                      <Col xs="2">
                        {conso.isReal? 
                        <input style={{"width": "80px"}} type="number" value={conso.consumptionKWh} onChange={v => setConsumptionByEnergyList(consumptionByEnergyList.map(c => {if(c?.energyCost?.contractType?.energy?.id ===conso?.energyCost?.contractType?.energy?.id){return {...c, consumptionKWh: v.target.value, edited:true}} else return c }))}/>
                        : <ToolTipText number={conso.consumptionKWh} digit="1" unit="kWh"/>
                        }</Col>
                      <Col xs="1">  
                        <ToolTipText number={conso.gesEmissions} digit="1" unit="kgCO2"/>
                      </Col>
                      <Col xs="1" > 
                        <ToolTipText number={conso.invoice} digit="1" unit="€"/>
                      </Col>
                      <Col><input checked={conso.isReal} onChange={v => setConsumptionByEnergyList(consumptionByEnergyList.map(c => {if(c?.energyCost?.contractType?.energy?.id ===conso?.energyCost?.contractType?.energy?.id){return {...c, isReal: !c.isReal, edited:true}} else return c }))}  type="checkbox"/></Col>
                   </Row>
                ))

                }
                
                <br/>
                 <Row>
                  <Col >
                    <Button size="sm" variant="primary" onClick={updateConsumptionByEnergyList}> Mettre à jour les consos ({API_UPDATE_CONSUMPTION})</Button>
                  </Col>
                  <Col >
                    <Button size="sm" variant="warning" onClick={getConsumptionList}> Réinitialiser les consos</Button>
                  </Col>
          </Row>
      </Container>
    </div>
  );
}

export default ConsumptionList;
