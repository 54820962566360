import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { API_GET_LOGBOOK_CHART, getChartDataAPI} from '../services/restService'

import Select from 'react-select';
import { ELECTRICITY, GAZ } from '../services/dataList';
import { Button } from 'react-bootstrap';
import ToolTipText from './ToolTipText';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const OPTIONS_ENERGY_BAR= {
    responsive: true,
    scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: false,
      },
    },
  };
/*
const LOGBOOK_CHART_TEMPLATE=
    {
        "energy": {
          "id": 2,
          "name": "Gaz"
        },
        "step": "1M",
        "startDate": "2022-06-10T00:00:00.000Z",
        "endDate": "2023-06-10T00:00:00.000Z",
        "consumptionKWh": [
          {
            "date": "2022-06-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 60
          },
          {
            "date": "2022-07-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 62
          },
          {
            "date": "2022-08-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 62
          },
          {
            "date": "2022-09-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 60
          },
          {
            "date": "2022-10-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 62
          },
          {
            "date": "2022-11-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 60
          },
          {
            "date": "2022-12-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 62
          },
          {
            "date": "2023-01-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 62
          },
          {
            "date": "2023-02-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 56
          },
          {
            "date": "2023-03-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 62
          },
          {
            "date": "2023-04-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 60
          },
          {
            "date": "2023-05-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 62
          }
        ],
        "gesEmissions": [
          {
            "date": "2022-06-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 13.620000000000001
          },
          {
            "date": "2022-07-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 14.074
          },
          {
            "date": "2022-08-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 14.074
          },
          {
            "date": "2022-09-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 13.620000000000001
          },
          {
            "date": "2022-10-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 14.074
          },
          {
            "date": "2022-11-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 13.620000000000001
          },
          {
            "date": "2022-12-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 14.074
          },
          {
            "date": "2023-01-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 14.074
          },
          {
            "date": "2023-02-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 12.712
          },
          {
            "date": "2023-03-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 14.074
          },
          {
            "date": "2023-04-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 13.620000000000001
          },
          {
            "date": "2023-05-10T00:00:00.000Z",
            "subscriptionValue": 0,
            "consumptionValue": 14.074
          }
        ],
        "invoice": [
          {
            "date": "2022-06-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.3863999999999996
          },
          {
            "date": "2022-07-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.4992799999999997
          },
          {
            "date": "2022-08-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.4992799999999997
          },
          {
            "date": "2022-09-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.3863999999999996
          },
          {
            "date": "2022-10-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.4992799999999997
          },
          {
            "date": "2022-11-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.3863999999999996
          },
          {
            "date": "2022-12-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.4992799999999997
          },
          {
            "date": "2023-01-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.4992799999999997
          },
          {
            "date": "2023-02-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.16064
          },
          {
            "date": "2023-03-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.4992799999999997
          },
          {
            "date": "2023-04-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.3863999999999996
          },
          {
            "date": "2023-05-10T00:00:00.000Z",
            "subscriptionValue": 9.07,
            "consumptionValue": 3.4992799999999997
          }
        ]
      }
      

*/
const STEP_DEFAULT = "1M";
const STEP_LIST=[
    {value: '1d', label : 'Quotidien'},
    {value: '1M', label : 'Mensuel'},
    {value: '6M', label : 'Semestriel'},
    {value: '1y', label : 'Annuel'},
]
const UNIT_DEFAULT = "consumptionKWh";
const INVOICE='invoice';
const UNIT_LIST=[
    {value: 'consumptionKWh', label : 'Consommation (kWh)', total: 'totalConsumptionKWh', unite:"kWh"},
    {value: INVOICE, label : 'Facture (€)', total: 'totalInvoice', unite: "€"},
    {value: 'gesEmissions', label : 'Emission de CO2 (gCO2eq)', total: 'totalGesEmissions', unite: "geqCO2"},
]

const DEFAULT_START_DATE = new Date((new Date()).getFullYear(), 1, 1);

export const LogbookChart = ({logbookId})=>{

    const [ startDate, setStartDate] = useState(DEFAULT_START_DATE)

    const [electricityDataChart, setElectricityDataChart]=useState(null)
    const [gasDataChart, setGasDataChart]=useState(null);

    const [electricityErr, setElectricityErr]=useState()
    const [gasErr, setGasErr]=useState(null);

    const [step, setStep] = useState(STEP_DEFAULT);
    const [unit, setUnit] = useState(UNIT_DEFAULT)


    useEffect(()=>{
      changeStartDate(0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
      getElectricityDataFromAPI();
      getGasDataFromAPI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logbookId, startDate, step, unit])



  


    const getEndDate = ()=>{
      let endDate= new Date(startDate.getTime());
      if(step ===  "1d"){
        //Pas journalier, on veut tout le mois précédent
        endDate = new Date(endDate.getFullYear(), endDate.getMonth()+1, 1)
      }
      else if (step === "1M" || step === "6M"){
        //Pas mensuel ou semestriel, on veut toute l'année
        endDate=new Date(endDate.getFullYear()+1,0,1);
        endDate.setDate(endDate.getDate()-1)
      }
      else if(step ==='1y') {
        const now= new Date();
        endDate.setFullYear(Math.max(startDate.getFullYear()+5, now.getFullYear()) )
      }
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59)
      return endDate;
    }

    const changeStartDate = (sens)=>{
      let newDate= new Date(startDate.getTime());
      if(step ===  "1d")
        //Pas journalier, on veut le mois précédent
        newDate = new Date( newDate.getFullYear(), newDate.getMonth()+sens, 1)
      else {
        //Pas mensuel ou semestriel, on veut toute l'année
        newDate = new Date( newDate.getFullYear()+sens, 0, 1, 0, 0, 1)
        newDate.setTime(newDate.getTime()+1000*60*60*1)
      }
      setStartDate(newDate);

    }

    const getElectricityDataFromAPI = ()=>{
       const endDate = getEndDate()
        getChartDataAPI(logbookId, ELECTRICITY, step, startDate, endDate)
            .then((res)=> {
            if(res.message) 
                setElectricityErr(res.message)
            else{
                setElectricityDataChart(res);
                setElectricityErr("")
            }
        })
    }

    const getGasDataFromAPI = ()=>{
        const endDate = getEndDate()
        getChartDataAPI(logbookId, GAZ, step, startDate, endDate)
            .then((res)=> {
            if(res.message) 
                setGasErr(res.message)
            else{
                setGasDataChart(res);
                setGasErr("")
            }
        })
    }


    const getChartData = (data) =>{

        //First add missing data if current month/year
        let mostRecentData = data[unit].reduce((res, val) => res.getTime()> new Date(val.date).getTime() ? res : new Date(val.date), new Date(2000,1,1))
        if(data[unit].length > 0 && step==="1d"){
          //Try to add days
          const endDate=getEndDate();
          endDate.setDate(endDate.getDate()-1)
         
        }
        else if (data[unit].length > 0 &&  (step ==="1M" )){
            //Try to add days
            while (parseInt(mostRecentData.getMonth()) < 11){
              mostRecentData.setMonth(mostRecentData.getMonth()+1)
              data[unit].push({ "date": new Date(mostRecentData.getTime()),
                                  "subscriptionValue": mostRecentData.subscriptionValue,
                                  "consumptionValue": 0})
            }
        }
        
        data[unit]= data[unit].map(d=> ({...d, date: new Date(d.date)}))


        const labels = data[unit].map(x=> {
          if(step ==="1d"|| step ==="6M")
            return (new Date(x.date).toLocaleDateString())
          else if (step ==="1M" )
            return (new Date(x.date).toLocaleString('default', { month: 'long' }))
          else if (step ==="1y" )
            return (new Date(x.date).getFullYear())
          return null
        })


        const toReturn= {
            labels: labels,
            datasets:[
              {
                  label: UNIT_LIST.find(u=> u.value === unit).label + " - " + STEP_LIST.find(s => s.value === step).label,
                  data: data[unit].map(x=> x.consumptionValue),
                  backgroundColor: 'rgb(75, 192, 192)',
              },
            {
                label: UNIT_LIST.find(u=> u.value === unit).label + " - " + STEP_LIST.find(s => s.value === step).label + " - Prévision",
                data: data[unit].map(x=> Math.max(0,x.theoricalConsumptionValue - x.consumptionValue)),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192,0.5)',

            }
          ]
        }
        
        if(unit === INVOICE){
            toReturn.datasets.unshift({
                label: "Abonnement (par fixe)",
                data: data.invoice.map(x=> x.subscriptionValue),
                backgroundColor: 'rgb(192, 75, 192)',
            })
        }

        return toReturn;
    }
    
    return (

        <Container>
            <Row><Col><h1>Charts</h1></Col></Row>
            <Row><Col >{API_GET_LOGBOOK_CHART}</Col></Row>
            <Row><Col className="error" >{electricityErr}</Col></Row>
            <Row><Col className="error" >{gasErr}</Col></Row>

            <Row>
              
            <Col xs="4"></Col>
                
                <Col xs="1">
                  <Button size="md" onClick={()=> {changeStartDate(-1)}}>{"<"}</Button>
                </Col>
                <Col xs="2">
                
                  <h3> { (step === '1d' ?  `${startDate.toLocaleString('default', { month: 'long' })} ` : "" )+ startDate.getFullYear()  }
                  </h3>
                </Col>
                <Col xs="1">
                  <Button size="md" onClick={()=> {changeStartDate(1)}}>{">"}</Button>
                </Col>
                
        
            </Row>
            <Row><Col>{startDate.toLocaleDateString()} - {getEndDate().toLocaleDateString()}</Col></Row>
            <Row>
               <Col xs="4"></Col>
               <Col xs="2">
                    Indicateur
                    <Select
                        options={UNIT_LIST} 
                        value={UNIT_LIST.find( el=> unit === el.value)} 
                        onChange={(res )=> setUnit(res.value) }
                        >
                    </Select>  
                </Col>
                
                <Col xs="2">
                    Précision
                    <Select
                        options={STEP_LIST} 
                        value={STEP_LIST.find( el=> step === el.value)} 
                        onChange={(res )=> setStep(res.value) }
                        >
                    </Select>  
                </Col>
                

            </Row>
            <Row>
                <Col xs="6">{electricityDataChart &&<h5>Electricité</h5>}</Col>
                <Col xs="6"> {gasDataChart && <h5>Gas</h5>}</Col>
            </Row>
            <Row>
                <Col xs="6">{electricityDataChart && 
                  (<ToolTipText number={electricityDataChart[UNIT_LIST.find(u=> u.value ===unit)?.total]}  
                                digits="2" 
                                unit={UNIT_LIST.find(u=> u.value ===unit)?.unite}/>)
                }</Col>
                <Col xs="6">{gasDataChart && 
                  (<ToolTipText number={gasDataChart[UNIT_LIST.find(u=> u.value ===unit)?.total]}  
                                digits="2" 
                                unit={UNIT_LIST.find(u=> u.value ===unit)?.unite}/>)
                }</Col>
            </Row>
            <Row>
                <Col xs="6">
                    {electricityDataChart && <Bar data={getChartData(electricityDataChart)} options={OPTIONS_ENERGY_BAR}  id="electricityChart"/>}
                </Col>
                <Col xs="6">
                    {gasDataChart && <Bar data={getChartData(gasDataChart)} options={OPTIONS_ENERGY_BAR}  id="gazChart"/>}
                </Col>
            </Row>

        </Container>

    )
}
