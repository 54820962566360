export const SURVEY_TEMPLATE={
  "codeNaf": "1071C",
  "siret": Math.floor(Math.random() *  Math.pow(10, 17)).toString(10).substring(0,14),
  "companyName": "Mon entreprise du " + new Date().toISOString().split('T')[0],
  "addressLib": "105 Route de Kerlann",
  "name": "Doe",
  "firstName": "John",
  "phoneNumber": "0122334455",
  "email": "xxx@xxx.xx",
  "additionalAddressDetails": "en bas du sentier",
  "postalCode": "56340",
  "companyPermanentStaff": 10,
  "companyTurnover": 100000,
  "isAirConditionerPresent": true,
  "companyFloorSpace": 90,
  "equipmentEnergyList": [
    1
  ],
  "heatingEnergyId": 2,
  "domesticHotWaterEnergyId": 3,
  "lightBulbTypeId": 1,
  "electricityContractId": 1,
  "electricityContractedPower": 36,
  "gasContractId": 5
}

export const YESNO_LIST=[
    {value: true, label:"Oui"}, 
    {value:false, label:"Non"}
  ];

export const USAGE_LIST=[
    {value: 1, label:"Chauffage (1)"},
    {value: 2, label:"ECS (2)"},
    {value: 3, label:"Ventilation (3)"},
    {value: 4, label:"Climatisation (4)"},
    {value: 5, label:"Auxiliaires (5)"},
    {value: 6, label:"Eclairage (6)"},
    {value: 7, label:"Equipements (usage spécifique) (7)"},
      
  ]

export const ELECTRICITY = 1;
export const GAZ = 2;

export const ENERGY_LIST= [
  {value: ELECTRICITY, label:"Electricité (1)"},
  {value: GAZ, label:"Gaz (2)"},
  {value: 3, label:"Fioul (3)"},
  {value: 4, label:"Bois-bûches (4)"},
  {value: 5, label:"GPL (5)"},
  {value: 6, label:"RCU (6)"},
  {value: 7, label:"Bois-plaquettes (7)"},
  {value: 8, label:"Bois-granulés (8)"}
];

export const LIGHTBULBTYPE_LIST=[  
  {value:1, label:"Ampoule fluocompacte (1)"},
  {value:2, label:"Ampoule à incandescence (ampoule à filament) (2)"},
  {value:3, label:"Ampoule halogene (3)"},
  {value:4, label:"LED (4)"},
  {value:5, label:"Tube fluorescent (5)"},
  {value:6, label:"Halogénures métalliques (6)"}
]

export const ELECTRICITYCONTRACT_LIST=[
  {value:1, label:"Base (1)"},
  {value:2, label:"Heures Pleines - Heures Creuses (3)"},
  {value:3, label:"Hors tarif Bleu (3)"},
]
export const GASCONTRACT_LIST=[
  {value:4, label:"Aucun (4)"},
  {value:5, label:"Base (5)"},
  {value:6, label:"B0 (6)"},
  {value:7, label:"B1 (7)"},
  {value:8, label:"B2I (8)"}
]