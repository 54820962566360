import React from 'react';

const ToolTipText = ({number, digits, unit}) => {
  return (<div className="mytooltip">
            {number?.toFixed(digits)} 
            <span className="tooltiptext">
                {number} 
            {number && unit ? " " + unit : ""}
            </span>
            {number && unit ? " " + unit : ""}
        </div>
  )};

export default ToolTipText;