import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { API_GET_LOGBOOK_METER,API_DELETE_METER, API_POST_METER, deleteMeterAPI, postMeterAPI, forceRefreshMetersAPI, checkProofsSendEmailAPI} from '../services/restService'
import { Button } from 'react-bootstrap';
import Select from 'react-select';

const LOGBOOK_METER_TEMPLATE={
    id: null,
    meterId:'PDL',
    type:'enedis',
    accessRightId: null,
    consumptionKWh: 0,
    createdAt: 'date',
    updatedAt: 'date',
    state: null,
    errorMessage: '',
}

export const LogbookMeterList = ({logbookId, logbookMeterList, refreshLogbook, err})=>{

    const [newLogbookMeter, setNewLogbookMeter]=useState(LOGBOOK_METER_TEMPLATE)
   
    const [errLogbookMeterList, setErrLogbookMeterList]=useState(null);

    useEffect(()=>{
      setErrLogbookMeterList(err)
    }, [err])

    const deleteMeter=(type, meterId)=>{
        deleteMeterAPI(logbookId, type, meterId)
            .then(res=>{
                if(!res.message){
                    console.log(res)
                    refreshLogbook()
                    console.log("Meter " +meterId +" deleted !")
                  }
                  else{
                    console.log(res.message)
                    setErrLogbookMeterList(res.message)
                    refreshLogbook()
                  }
            })
    }

    const postMeter=()=>{
        postMeterAPI(logbookId, newLogbookMeter)
            .then(res=>{
                if(!res.message){
                    console.log(res)
                    console.log("Meter " +res?.meterId +" added !")
                    refreshLogbook()
                  }
                  else{
                    console.log(res.message)
                    setErrLogbookMeterList(res.message)
                    refreshLogbook()
                  }
            })
    }

    const forceRefreshMeters= ()=>{
        forceRefreshMetersAPI()
        .then((res)=> {
          if(res.message) 
            setErrLogbookMeterList(res.message)
          refreshLogbook()
      })
    }

    const checkProofsSendEmail= ()=>{
        checkProofsSendEmailAPI()
        .then((res)=> {
          if(res.message) 
            setErrLogbookMeterList(res.message)
          refreshLogbook()
      })
    }

    
    return (

        <Container>
            <Row><Col><h1>Mes Compteurs connectés</h1></Col></Row>
            <Row><Col >({API_GET_LOGBOOK_METER})</Col></Row>
                      
            <br/>
            <Row>
            <Col></Col> 
            <Col><Button variant="outline-secondary" size="sm" onClick={()=> forceRefreshMeters()}>Rafraichir les données des compteurs</Button></Col>   
            <Col></Col> 
            <Col><Button variant="outline-secondary" size="sm" onClick={()=> checkProofsSendEmail()}>Envoyer les preuves à controler par mail</Button></Col>   
            <Col></Col> 
            </Row>          
            <br/>  
            <Row><Col className="error">{errLogbookMeterList}</Col></Row>
            <Row>
                <Col xs="2"><b>Identifiant</b></Col>
                <Col xs="2"><b>PDL/PRM</b></Col>
                <Col xs="1"><b>Type</b></Col>
                <Col xs="2"><b>accessRightId</b></Col>
                <Col xs="1"><b>Conso(kWh)</b></Col>
                <Col xs="1"><b>state</b></Col>
                <Col xs="1"><b>Date de création</b></Col>
                <Col xs="1"><b>Dernière synchro</b></Col>
                <Col xs="1"><b>{API_DELETE_METER}</b></Col>
            
            </Row>
            
            { logbookMeterList && logbookMeterList.length &&
            logbookMeterList.map(meter=>(
            <Row key={"meter_"+meter.id}>
                <Col xs="2"> {meter.id} </Col>
                <Col xs="2"> {meter.meterId} </Col>
                <Col xs="1"> {meter.type} </Col>
                <Col xs="2"> {meter.accessRightId} </Col>
                <Col xs="1"> {meter.consumptionKWh} </Col>
                <Col xs="1"className="mytooltip"> {meter.state} {(meter.errorMessage !== null && meter.errorMessage !== "") && <span className="tooltiptext">{meter.errorMessage}</span> } </Col>
                <Col xs="1"> {meter.createdAt} </Col>
                <Col xs="1"> {meter.updatedAt} </Col>
                <Col xs="1"> <Button variant="danger"  size="sm"onClick={()=> deleteMeter(meter.type, meter.meterId)}> Révoquer </Button> </Col>
                
            </Row>
            ))
            }
            <Row>
                <Col>  </Col>
                <Col>   <Select
                                options={[{id:"enedis", label:"enedis"},{id:"grdf", label:"grdf"}]}
                                value={{id: newLogbookMeter.type, label: newLogbookMeter.type}}
                                onChange={(res )=> setNewLogbookMeter({...newLogbookMeter, type: res.id})} 
                                
                            >
                            </Select></Col>
                <Col> <input value={newLogbookMeter.meterId} onChange={r=> setNewLogbookMeter({...newLogbookMeter, meterId: r.target.value})} type="text"/> </Col>
                <Col> <Button variant="primary"  size="sm" onClick={()=> postMeter()}> {API_POST_METER} </Button> </Col>
                <Col>  </Col>
            </Row>
           
            <Row> 
                <Col xs="3"><b>Rappel des codes utilisables en recette</b></Col>
            </Row>  
            <Row> 
                <Col xs="1">ENEDIS</Col>
                <Col xs="2">"N'importe quel code"</Col>
                <Col xs="3"> Tous les PDL fonctionnent</Col>
            </Row>            
            <Row> 
                <Col xs="1">GRDF</Col>
                <Col xs="2">"bad_postal_code"</Col>
                <Col xs="3"> Le compteur se situe dans un autre code postal</Col>
            </Row>
            <Row>
                <Col xs="1">GRDF</Col>
                <Col xs="2">"09739073753501"</Col>
                <Col xs="3">Droit révoqué</Col>
            </Row>
            <Row>
                <Col xs="1">GRDF</Col>
                <Col xs="2">"09739073753502"</Col>
                <Col xs="3">Droit Obsolète</Col>
            </Row>
            <Row>
                <Col xs="1">GRDF</Col>
                <Col xs="2">"09739073753503+code_unique"</Col>
                <Col xs="3">Droit Actif</Col>
            </Row>
            <Row>
                <Col xs="1">GRDF</Col>
                <Col xs="2">"GI999455+code_unique"</Col>
                <Col xs="3">Droit Actif</Col>
            </Row>
            <Row>
                <Col xs="1">GRDF</Col>
                <Col xs="2">"GI999001+code_unique"</Col>
                <Col xs="3">Droit Actif</Col>
            </Row>
            <Row>
                <Col xs="1">GRDF</Col>
                <Col xs="2">"11300000000000+code_unique"</Col>
                <Col xs="3">Droit Actif</Col>
            </Row>
            <Row>
                <Col xs="1">GRDF</Col>
                <Col xs="2">"22090020010001+code_unique"</Col>
                <Col xs="3">Droit Actif</Col>
            </Row>
        </Container>

    )
}
